import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Icon, Menu, Dimmer, Label } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, uniqBy } from 'lodash';
import Loading from '../../../components/ui/Loading';

import Form from '../../../components/ui/Form';
import BetaButton from '../../../components/ui/Button';
import ColoredCheckbox from '../../../components/ColoredCheckbox';
import RecipientsModal from '../../../components/ui/RecipientsModal';

import EntityItem from '../../Entity/Item';

import { __ } from '../../../i18n';

const isEntitySelected = (entityId, entityGroups, allGroups, entityGroupIsSelected) => {
  if (entityGroupIsSelected) return true;
  if (entityGroups <= 1) return false;
  return entityGroups.some(entityGroup => (
    allGroups.some(group => (
      group.selected && entityGroup.id === group.id && (
        !group.entities.nodes.length || group.entities.nodes.filter(e => e.id === entityId).length)
    ))
  ));
};

const entityRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $search: String) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      groups (search: $search) {
        nodes {
          name
          id: dbId
          expanded
          loaded
          recipientsCount
          key
          organizationId
          internal
          seeAll
          loading
          entities (limit: 0) {
            nodes {
              id: dbId
              ... EntityItemRecipient
            }
          }
        }
      }
      recipients (search: $search) {
        nodes {
          id: dbId
          roles (limit: 100) { nodes { id: dbId } }
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityGroupRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $groupId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: groupRecipients (groupId: $groupId, limit: 1400) {
        nodes {
          id: dbId
          selected (selected: $selected)
          roles (limit: 100) { nodes { id: dbId } }
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityStaffRecipientsQuery = gql`
  query EntityStaffRecipientsQuery($entityId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: staffRecipients (limit: 800) {
        nodes {
          id: dbId
          selected (selected: $selected)
          roles (limit: 100) { nodes { id: dbId } }
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

@inject('store', 'client')
@graphql(gql`query EntityRecipientsQuery ($id: ID!, $limit: Int) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        selected
        groups (limit: $limit) {
          nodes {
            name
            id: dbId
            selected
            expanded
            loaded
            recipientsCount
            key
            organizationId
            internal
            seeAll
            loading
            entities (limit: 0) {
              nodes {
                id: dbId
                selected
                ... EntityItemRecipient
              }
            }
          }
        }
        recipients (limit: 0) {
          nodes {
            id: dbId
            selected
            ... EntityItemRecipient
          }
        }
      }
    }
  }
${EntityItem.fragments.recipient}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.entity.id,
      limit: ownProps.entity.groups.totalCount
    }
  })
})
@observer
export default class EntityRecipients extends React.Component {
  state = {
    loading: false,
    recipientsModal: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.loaded && nextProps.data && nextProps.data.node) {
      this.loaded = true;
      this.originalData = cloneDeep(nextProps.data.node);

      this.addStaffItem(nextProps);
    }
  }

  onSubmit = async () => {
    const { fetchOnSubmit } = this.props;

    if (fetchOnSubmit) await this.fetchAll();

    this.props.onSubmit(this.props.data.node);
  }

  onCancel = () => {
    this.props.data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node = this.originalData;

      return nextResult;
    });

    this.props.onCancel();
  }

  onSelectAll = () => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.selected = !nextResult.node.selected;

      nextResult.node.groups.nodes.forEach((group) => {
        group.selected = nextResult.node.selected;

        const entityIdsInGroup = group.entities.nodes.map(e => e.id);

        nextResult.node.recipients.nodes
          .map((e) => {
            if (entityIdsInGroup.includes(e.id)) {
              e.selected = nextResult.node.selected;
            }
            return e;
          });
      });

      return nextResult;
    });
  }

  onExpandGroup = async (group, index) => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      const node = nextResult.node.groups.nodes[index];

      node.expanded = !node.expanded;

      if (!node.loaded) {
        node.loading = true;
      }

      return nextResult;
    });

    if (group.loaded) return;

    this.fetchAndToggleGroup(group, index, false, null, false, true);
  }

  onSearchChange = async (value, selectedEntities, selectedGroups) => {
    if (!value) {
      return;
    }
    const { client, entity, data } = this.props;
    const response = await client.query({
      query: entityRecipientsQuery,
      variables: {
        entityId: entity.id,
        search: value
      },
    });

    const searchResult = response.data.node;
    searchResult.recipients.nodes = searchResult.recipients.nodes.filter(object => selectedEntities.findIndex(i => i.id === object.id) === -1);
    searchResult.groups.nodes = searchResult.groups.nodes.filter(object => selectedGroups.findIndex(i => i.id === object.id) === -1);
    this.setState({ searchResult, searching: false });
  }

  addStaffItem = (props) => {
    const { data, store } = props;

    if (store.currentEntity.organization.confAdminList &&
      (!data.node.groups.nodes.length || data.node.groups.nodes[0].id !== 'staff')) {
      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);

        nextResult.node.groups.nodes.unshift({
          ...nextResult.node.groups.nodes[0],
          id: 'staff',
          name: __('Staff'),
          recipientsCount: null,
          entities: {
            nodes: [],
            __typename: 'GroupEntitiesConnection'
          }
        });
        return nextResult;
      });
    }
  }

  toggleEntity = (entity, setFalse = false, fromSearch = false) => {
    const { data } = this.props;

    const finalIndex = data.node.recipients.nodes.findIndex(sr => sr.id === entity.id);

    return data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);
      if (nextResult.node.recipients.nodes[finalIndex]) {
        if (nextResult.node.recipients.nodes[finalIndex].selected && fromSearch) { //If you are searching and it is selected, you don`t toggle selection
          return;
        }
        nextResult.node.recipients.nodes[finalIndex].selected = setFalse ? false :
          !nextResult.node.recipients.nodes[finalIndex].selected;
      } else {
        nextResult.node.recipients.nodes.push({ ...entity, selected: true });
      }


      // Find groups that contain entity, remove selected
      const entityIdsInSelectedGroups = [];

      nextResult.node.groups.nodes = nextResult.node.groups.nodes.map((group) => {
        if (group.entities.nodes
          .findIndex(e => e.id === entity.id) > -1) {
          if (group.selected) {
            group.selected = false;

            group.entities.nodes.forEach((e) => {
              entityIdsInSelectedGroups.push(e.id);
            });
          }
        }

        return group;
      });

      nextResult.node.recipients.nodes = nextResult.node.recipients.nodes.map((e) => {
        if (entityIdsInSelectedGroups.includes(e.id) && e.id !== entity.id) {
          e.selected = true;
        }

        return e;
      });

      return nextResult;
    });
  }

  fetchAndToggleGroup = async (group, index, toggleCheck = false, setTrue = null, fromSearch = false, isExpand = false) => {
    const { data, entity, client } = this.props;

    let response;
    const finalIndex = data.node.groups.nodes.findIndex(sr => sr.id === group.id);

    if (!group.loaded) {
      response = await client.query({
        query: group.id === 'staff' ? entityStaffRecipientsQuery : entityGroupRecipientsQuery,
        variables: {
          entityId: entity.id,
          groupId: group.id,
          selected: setTrue || toggleCheck ? !group.selected : group.selected
        },
      });
    }

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      const node = nextResult.node.groups.nodes[index || index === 0 ? index : finalIndex];
      if (node.selected && fromSearch) {
        return;
      }

      if (toggleCheck) {
        nextResult.node.selected = false;

        node.selected = setTrue || !node.selected;
      }

      if (response) {
        node.loading = false;
        node.loaded = true;

        // Don't unselect entity if it belongs to another group that is selected
        response.data.node.recipients.nodes = response.data.node.recipients.nodes.map(recipient => ({
          ...recipient,
          selected: isEntitySelected(recipient.id, recipient.roles.nodes, nextResult.node.groups.nodes, recipient.selected)
        }));

        node.entities = response.data.node.recipients;
        nextResult.node.recipients.nodes = cloneDeep(uniqBy([
          ...nextResult.node.recipients.nodes,
          ...response.data.node.recipients.nodes
        ], 'id'));
      } else if (!node.selected) {
        // Don't unselect from other groups if group is selected
        const selectedEntityIdsInOtherGroups = [];

        nextResult.node.groups.nodes
          .filter(g => g.selected).forEach((g) => {
            g.entities.nodes.filter(e => e.selected).forEach((e) => {
              selectedEntityIdsInOtherGroups.push(e.id);
            });
          });

        node.entities.nodes = node.entities.nodes.map((e) => {
          if (!selectedEntityIdsInOtherGroups.includes(e.id)) {
            e.selected = false;
          }

          return e;
        });
      } else {
        // Don't unselect from other groups if group is selected
        const selectedEntityIdsInOtherGroups = [];

        nextResult.node.groups.nodes
          .filter(g => g.selected).forEach((g) => {
            g.entities.nodes.filter(e => e.selected).forEach((e) => {
              selectedEntityIdsInOtherGroups.push(e.id);
            });
          });

        node.entities.nodes = node.entities.nodes.map((e) => {
          e.selected = true;
          return e;
        });
      }
      if (!isExpand) {
        nextResult.node.recipients.nodes = uniqBy([...node.entities.nodes, ...nextResult.node.recipients.nodes], 'id');
      }

      return nextResult;
    });
  }

  fetchAll = async () => {
    this.setState({ loading: true });

    const { data, entity, client } = this.props;
    const response = {};
    const promises = data.node.groups.nodes.filter(group => group.selected && !group.loaded).map(group => client.query({
      query: group.id === 'staff' ? entityStaffRecipientsQuery : entityGroupRecipientsQuery,
      variables: {
        entityId: entity.id,
        groupId: group.id,
        selected: false
      },
    }).then((result) => {
      response[group.id] = cloneDeep(result);
    }));

    await Promise.all(promises);

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.groups.nodes.filter(group => group.selected).forEach((group) => {
        if (response[group.id]) {
          group.entities = response[group.id].data.node.recipients;

          group.loaded = true;
          nextResult.node.recipients.nodes = uniqBy([
            ...nextResult.node.recipients.nodes,
            ...response[group.id].data.node.recipients.nodes
          ], 'id');
        }
      });

      return nextResult;
    });

    this.setState({ loading: false });
  }

  renderRow = (group, key) => {
    const { selectedOnly, disableEntitySelect, data, isMobile } = this.props;
    const selectedEntities = group.entities &&
      group.entities.nodes.map((entity) => {
        if (entity.selected || group.selected) return true;

        return !!data.node.groups.nodes.filter(g => g.selected && (g.entities.nodes
          .findIndex(e => e.id === entity.id) > -1)).length;
      }).filter(selected => selected).length;

    return ([
      !selectedOnly && <Menu.Item style={isMobile ? { height: '64px', backgroundColor: group.selected ? '#F2F7FF' : 'unset' } : {}} key={group.id}>
        {!isMobile && <ColoredCheckbox
          name="entity"
          data-action="select-recipient"
          data-value={group.id}
          value={group.id}
          radio
          radioChecked
          bold={false}
          color="#084FFF"
          checked={group.selected}
          indeterminate={(selectedEntities > 0) && !group.selected}
          onClick={() => this.fetchAndToggleGroup(group, key, true)}
          style={{ verticalAlign: 'middle', marginRight: '10px' }}
        />}
        <div data-action="expand-group" onClick={() => this.onExpandGroup(group, key)} style={{ cursor: 'pointer', width: '100%', display: isMobile ? 'flex' : 'unset', alignItems: isMobile ? 'center' : 'unset' }}>
          {isMobile &&
            <div style={{ float: 'left', display: 'flex' }}>
              <Icon name={group.expanded ? 'angle up' : 'angle right'} style={{ fontWeight: '300', fontSize: '24px', lineHeight: '24px', color: '#00000066' }} />
            </div>
          }
          {isMobile &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 400, fontSize: '16px' }}>
                {(group.name.length > 35) ? (group.name.substring(0, 35) + '...') : group.name}
              </span>
              {group.entities && group.recipientsCount !== null &&
                <span style={{ fontWeight: 400, fontSize: '16px', color: '#00000066', marginTop: '3px' }}>
                  {group.recipientsCount === 1 ? __('1 person', group.recipientsCount) : __('%s people', group.recipientsCount)}
                </span>
              }
            </div>
          }
          {!isMobile &&
            <span style={{ fontWeight: 400, fontSize: '16px' }}>{(group.name.length > 35) ? (group.name.substring(0, 35) + '...') : group.name}</span>
          }
          {!isMobile &&
            <div style={{ float: 'right', display: 'flex' }}>
              {
                group.entities && group.recipientsCount !== null &&
                <div style={{ display: 'flex', fontSize: '13px' }}>
                  <Icon name="users" style={{ color: '#a0a0a0' }} />
                  <span style={{ padding: '0px 21px 0px 7px' }}>{
                    group.recipientsCount}</span>
                </div>
              }
              <Icon name={group.expanded ? 'angle up' : 'angle right'} />
            </div>
          }
        </div>
        {isMobile && <ColoredCheckbox
          name="entity"
          data-action="select-recipient"
          data-value={group.id}
          value={group.id}
          radio
          radioChecked
          bold={false}
          color="#084FFF"
          checked={group.selected}
          indeterminate={(selectedEntities > 0) && !group.selected}
          onClick={() => this.fetchAndToggleGroup(group, key, true)}
          style={{ verticalAlign: 'middle', marginRight: '10px' }}
        />}
      </Menu.Item>,
      (group.expanded || selectedOnly) &&
      (group.entities.nodes ?
        <Menu.Menu key={`${group.id}-expanded`} className="entity-item">
          {
            group.entities.nodes.map((entity) => {
              let selected = entity.selected || group.selected;

              if (!selected) {
                selected = !!data.node.groups.nodes.filter(g => g.selected && (g.entities.nodes
                  .findIndex(e => e.id === entity.id) > -1)).length;
              }

              return (<EntityItem
                wrapType
                coloredCheckbox
                invertCheckbox={isMobile}
                data-action="select-entity"
                data-value={entity.id}
                style={{ fontSize: '.95em', color: '#000', display: 'flex', alignItems: 'center', height: '56px', margin: '1px 0px 0px', backgroundColor: entity.selected ? 'rgba(74, 144, 226, 0.05)' : '', justifyContent: isMobile ? 'space-between' : 'unset' }}
                key={entity.id}
                entity={entity}
                onClick={disableEntitySelect ? () => { } : () => this.toggleEntity(entity, selected && !entity.selected)}
                indicator={disableEntitySelect ? null : 'checkbox'}
                position={isMobile ? 'right' : 'left'}
                selected={selected}
              />);
            })
          }
        </Menu.Menu>
        :
        (!selectedOnly &&
          <Menu.Item key={`${group.id}-loading`}>
            <Dimmer active inverted>
              <Loader style={{ display: 'table', margin: 'auto' }} inverted inline="centered" />
            </Dimmer>
          </Menu.Item>))
    ]);
  }

  onChangeText = (value, selectedEntities, selectedGroups) => {
    clearTimeout(this.lastRequestId);
    if (value.length > 0) this.setState({ searching: true });
    else this.setState({ searching: false });
    let id;
    this.lastRequestId = id = setTimeout(() => this.onSearchChange(value, selectedEntities, selectedGroups), 600);
  }

  toggleRecipientsModal = () => {
    this.setState((previousState => ({ recipientsModal: !previousState.recipientsModal })));
  }

  render() {
    const { data, selectedOnly, isMobile, submitButton, groupsOnly } = this.props;
    const { text, searchResult, searching, recipientsModal } = this.state;
    const formProps = {};

    if ((data.loading && !data.node) || !data.node) return <Loader inverted />;
    const selectedEntities = data.node.recipients.nodes.filter(recipient => recipient.selected);
    const selectedGroups = data.node.groups.nodes.filter(recipient => recipient.selected);
    const entitiesCount = selectedEntities.length;
    const groupsCount = selectedGroups.length;
    const originalEntitiesCount = this.originalData && this.originalData.recipients && this.originalData.recipients.nodes && this.originalData.recipients.nodes.filter(recipient => recipient.selected).length;
    let actionButtonsLeft = [];
    if (groupsOnly) {
      actionButtonsLeft = [groupsCount ? <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '24px', color: '#00000099', fontSize: '16px' }}>{groupsCount === 1 ? __('1 selected') : __('%s selected', groupsCount)}</div> : null];
    } else {
      actionButtonsLeft = [entitiesCount ? <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '24px', color: '#00000099', fontSize: '16px' }}>{entitiesCount === 1 ? __('1 selected') : __('%s selected', entitiesCount)}</div> : null];
    }

    const actionButtonsRight = [
      <div>
        <BetaButton
          style={{ marginRight: '12px' }}
          data-action="cancel"
          round
          transparent
          floated="left"
          text={__('Cancel')}
          onClick={() => this.onCancel()}
        />
        <BetaButton
          data-action="submit"
          round
          loading={this.state.loading}
          icon={{ name: 'check' }}
          text={submitButton && submitButton.text ? submitButton.text : originalEntitiesCount ? __('Save') : __('Add')}
          onClick={() => this.onSubmit()}
        />
      </div>
    ];

    let recipientsViewer;

    if (groupsOnly) {
      recipientsViewer = (
        groupsCount ?
          (<div style={{ height: '72px', display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'fixed', bottom: 0, width: '100%', background: '#fff' }}>
            {groupsCount > 0 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, marginLeft: '12px' }}
                content={selectedGroups[0].name.length > 24 ? (selectedGroups[0].name.substring(0, 21) + '...') : selectedGroups[0].name}
                removeIcon="delete"
                onRemove={() => this.fetchAndToggleGroup(selectedGroups[0], null, true)}
              />
            }
            {
              groupsCount > 1 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                onClick={() => this.toggleRecipientsModal()}
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, minWidth: '60px', justifyContent: 'center' }}
                content={<span>{`+${groupsCount - 1}`}</span>}
              />
            }
          </div>) : null);
    } else {
      recipientsViewer = (
        entitiesCount ?
          (<div style={{ height: '72px', display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'fixed', bottom: 0, width: '100%', background: '#fff' }}>
            {entitiesCount > 0 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, marginLeft: '12px' }}
                content={selectedEntities[0].fullname.length > 24 ? (selectedEntities[0].fullname.substring(0, 21) + '...') : selectedEntities[0].fullname}
                removeIcon="delete"
                onRemove={() => this.toggleEntity(selectedEntities[0], undefined)}
              />
            }
            {
              entitiesCount > 1 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                onClick={() => this.toggleRecipientsModal()}
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, minWidth: '60px', justifyContent: 'center' }}
                content={<span>{`+${entitiesCount - 1}`}</span>}
              />
            }
          </div>) : null);
    }

    return (
      <Form
        id="EntityRecipients"
        {...this.props}
        withPortal
        actionButtonsRight={!isMobile ? actionButtonsRight : false}
        actionButtonsLeft={!isMobile ? actionButtonsLeft : false}
        hasDivider={isMobile}
        {...formProps}
        onSubmit={() => this.onSubmit()}
        header={{
          title: isMobile ? null : selectedOnly ? __('Selected recipients') : __('Recipients'),
          onClose: () => this.onCancel(),
          invertCloseButton: !!isMobile,
          headerItem: isMobile ? <BetaButton
            data-action="submit"
            round
            text={originalEntitiesCount ? __('Save') : __('Add')}
            onClick={() => this.onSubmit()}
            style={{ height: '40px', width: '100px', justifyContent: 'center', display: 'flex' }}
          /> : null
        }}
        onCancel={() => this.onCancel()}
      >
        <div className={'EntityRecipientsContent'} style={{ minHeight: 'calc(70vh)' }}>

          <div className="searchRecipients" style={{ borderBottom: isMobile ? '1px solid #f1f3f5' : 'none', alignItems: 'center', display: 'flex', height: isMobile ? '60px' : '48px' }}>
            {!isMobile &&
              <Icon className={'search'} />
            }
            <input
              className={`search${isMobile ? ' full-screen' : ''}`}
              type="text"
              placeholder={__('Search...')}
              onChange={(e) => { this.setState({ text: e.target.value }); this.onChangeText(e.target.value, selectedEntities, selectedGroups); }}
              value={this.state.text}
            />
          </div>
          {!!text &&
            (!searching ?
              <div>
                {searchResult.groups.nodes.map(r =>
                  <div onClick={() => { this.setState({ text: '' }); this.fetchAndToggleGroup({ ...r, selected: null }, null, true, undefined, true); }} style={{ height: '50px', width: '100%', display: 'flex', flexDirection: 'row', cursor: 'pointer', padding: '9px 16px', alignItems: 'center' }}>
                    <Icon name="users" style={{ marginRight: '8.5px' }} />
                    <span style={{ fontWeight: 400, fontSize: '16px' }}>{r.name}</span>
                  </div>)}
                {!groupsOnly && searchResult.recipients.nodes.map((r, i) =>
                  <div>
                    <EntityItem
                      wrapType
                      coloredCheckbox
                      data-action="select-entity"
                      data-value={r.id}
                      style={{ cursor: 'pointer', padding: '9px 16px', fontSize: '.95em', color: '#000', display: 'flex', alignItems: 'center', margin: 0, backgroundColor: r.selected ? 'rgba(74, 144, 226, 0.05)' : '' }}
                      key={r.id}
                      entity={r}
                      onClick={() => { this.setState({ text: '' }); this.toggleEntity(r, undefined, true); }}
                      position="left"
                    />
                  </div>
                )}

              </div>
              :
              <div>
                <Loading width={40} height={40} style={{ marginTop: '1rem' }} />
              </div>
            )
          }
          {
            !text &&
            <Menu className={'recipients-modal'} secondary vertical fluid>
              {!this.props.selectedOnly &&
                <Menu.Menu key="all" className="item" style={isMobile ? { justifyContent: 'space-between', borderBottom: '1px solid #f1f3f5', color: '#084FFF' } : {}}>
                  {isMobile && <span style={{ fontWeight: 400, fontSize: '16px', cursor: 'pointer' }} onClick={this.onSelectAll} >{__('Select all groups')}</span>}
                  <ColoredCheckbox
                    name="entity"
                    data-action="select-all"
                    radio
                    radioChecked
                    bold={false}
                    color="#084FFF"
                    checked={data.node.selected}
                    onClick={this.onSelectAll}
                    style={{ verticalAlign: 'middle', marginRight: '10px' }}
                  />
                  {!isMobile && <span style={{ fontWeight: 400, fontSize: '16px', cursor: 'pointer' }} onClick={this.onSelectAll} >{__('Select All')}</span>}
                </Menu.Menu>
              }
              {data.node.groups.nodes.map((group, key) => this.renderRow(group, key))}
            </Menu>
          }

        </div>
        {isMobile && recipientsViewer}
        {
          !groupsOnly && recipientsModal &&
          <RecipientsModal selectedEntities={selectedEntities} onClose={() => this.toggleRecipientsModal()} onRemove={e => this.toggleEntity(e, undefined)} />
        }
        {
          groupsOnly && recipientsModal &&
          <RecipientsModal groups selectedEntities={selectedGroups} onClose={() => this.toggleRecipientsModal()} onRemove={g => this.fetchAndToggleGroup(g, null, true)} />
        }
      </Form>
    );
  }
}
