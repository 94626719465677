import React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Segment, Icon, Divider, Loader, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import Page from '../../components/Page';
import Pagination from '../../components/Pagination';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import MediaItem from '../Media/Item';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query EntityMediasQuery (
  $id: ID!,
  $limit: Int,
  $offset: Int
) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        medias(type: [IMAGE, VIDEO, AUDIO], limit: $limit, offset: $offset) {
          nodes {
            id: dbId
            original: uri(size:"w1280")
            thumbnail(size:"w320")
            mimetype
            ...MediaItemImage
            ...MediaItemVideo
            ...MediaItemAudio
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
  ${MediaItem.fragments.image}
  ${MediaItem.fragments.video}
  ${MediaItem.fragments.audio}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
@observer
export default class EntityMedias extends Page {

  renderRow = (media, key) => {
    let mediaType = 'video';
    if (media.mimetype.includes('image')) mediaType = 'image';
    else if (media.mimetype.includes('audio')) mediaType = 'audio';

    return (
      <Grid.Column
        data-action="open-media-item"
        key={key}
        onClick={() => this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments[mediaType], media)} />)}
        style={{
          cursor: 'pointer',
          backgroundImage: 'url(' + (media.mimetype.includes('image') ? media.original : media.thumbnail) + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100px',
          width: '100px',
          margin: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {media.mimetype.includes('image') ? null : <Icon name="play circle" size="big" />}
      </Grid.Column>
    );
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const medias = data.node.medias;
    const { nodes, pageInfo } = medias;

    return (
      <div id="EntityMedias">
        {
          nodes.length ?
            <Segment textAlign="center" style={{ padding: '2rem 1rem' }}>
              <Grid style={{ justifyContent: 'center' }}>
                {
                  nodes.map((media, key) => this.renderRow(media, key))
                }
              </Grid>
              {
                pageInfo && pageInfo.hasNextPage &&
                [
                  <Divider hidden />,
                  <Pagination
                    source={nodes}
                    pagination={{
                      graphql: true,
                      hasNextPage: pageInfo && pageInfo.hasNextPage,
                      loading: data.loading
                    }}
                    onLoadMore={() => this.loadMore('medias')}
                  />
                ]
              }
            </Segment>
            :
            <Segment basic textAlign="center">
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-30.png')} centered />
              <div style={{ color: '#9696a0' }}>{__('There are no items to display')}</div>
            </Segment>
        }
        {this.props.children}
      </div>
    );
  }
}
