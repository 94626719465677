import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader, Menu, Form, Image, Segment, Button, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';

import ListView from '../../components/ListView';
import Page from '../../components/Page';
import Input from '../../components/Input';

import MomentItem from '../Moment/Item';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query EntityMomentsQuery (
  $entityId: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [EntityMomentsOrder],
  $deleteNotifications: Boolean,
  $updateStatus: RecipientStatus,
  $updateAccessed: Boolean
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
       moments(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, deleteNotifications: $deleteNotifications, updateAccessed: $updateAccessed, updateStatus: $updateStatus ) {
          nodes {
            id: dbId
            ... MomentItem
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
  ${MomentItem.fragments.moment}
`, {
  options: (ownProps) => {
  const { params, store } = ownProps;
  const userHasEntity = params.entity_id && store.currentUser.entities &&
  (store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === params.entity_id).length > 0);

  return {
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only',
  variables: {
  entityId: ownProps.params.entity_id,
  limit: PAGE_SIZE,
  offset: 0,
  orderBy: { column: 'CREATED', direction: 'DESC' },
  search: ownProps.location.query.search ? decodeURIComponent(ownProps.location.query.search) : '',
  deleteNotifications: userHasEntity,
  updateStatus: userHasEntity ? 'READ' : undefined,
  updateAccessed: userHasEntity
  }
  };
  }
  })
@observer
export default class EntityMoments extends Page {
  constructor(props) {
    super(props);

    this.state = {
      search: props.location.query.search || ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const search = nextProps.location.query.search ? decodeURIComponent(nextProps.location.query.search) : '';

    if (search !== this.state.search) {
      this.setState({ search });
    }
  }

  renderFilter = () => {
    const { store, router } = this.props;

    const canCreateMoment = !((store.entity.type === 'STUDENT') || (store.entity.id !== store.currentEntity.id) || !store.entity.organization.features.moments);
    const inputWidth = canCreateMoment ? (this.isMobile() ? '80%' : '70%') : '100%';

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Input
          name="search"
          icon={null}
          placeholder={__('Search by hashtags')}
          value={this.state.search}
          onChange={(e, { value }) => this.setState({ search: value })}
          style={{ display: 'flex', flex: 1, height: '3rem', maxWidth: inputWidth }}
          action={{
            icon: 'search',
            basic: true,
            onClick: () => this.onUniqueParameterChange('search', encodeURIComponent(this.state.search))
          }}
        />

        {canCreateMoment &&
          <div
            data-action="open-moment-add"
            onClick={() => { router.push(`/entities/${this.props.params.entity_id}/moments/add`); }}
            style={{ display: 'flex', backgroundColor: '#084FFF', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', padding: '1rem', height: '3rem', cursor: 'pointer' }}
          >
            {!this.isMobile() ? <span style={{ color: '#FFF' }}>{__('New moment')}</span> : <Icon name="plus" style={{ margin: 0, color: '#FFF', fontSize: '1.25rem' }} />}
          </div>}
      </div>
    );
  }

  renderRow = (moment, key) =>
    <MomentItem
      key={key}
      moment={moment}
      params={this.props.params}
    />;

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { moments } = data.node;

    return (
      <div id="EntityMoments">
        {this.renderFilter()}
        {
          moments.nodes.length ?
            <ListView
              segment={false}
              selection={false}
              celled={false}
              style={{ marginTop: 24, marginBottom: 24 }}
              renderRow={this.renderRow}
              source={moments.nodes}
              pagination={{
                graphql: true,
                hasNextPage: moments.pageInfo && moments.pageInfo.hasNextPage,
                loading: data.loading
              }}
              onLoadMore={() => this.loadMore('moments')}
            />
            :
            <Segment basic textAlign="center">
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-31.png')} centered />
              <div style={{ color: '#9696a0' }}>{__('Ops, your institution has not posted any moments yet :(')}</div>
            </Segment>
        }

        {this.props.children}
      </div>
    );
  }
}
