import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, omit } from 'lodash';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import EntityForm from '../Entity/Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const createPicture = entityId => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

@inject('store', 'client', 'api')
@graphql(gql`query EntityAddQuery ($id: ID!) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        type
        confCharge
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        groups (limit: 400) {
          totalCount
          nodes {
            type
            name
            id: dbId
          }
        }
        tags (limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
        features {
          messageManager
          moments
          reports
          api
          charges
          deleteMessages
          editMessages
          forms
          links
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.organization_id
    }
  })
})
@graphql(gql`mutation createEntity($createEntityMutation: CreateEntityInput!) {
  createEntity(input: $createEntityMutation) {
    clientMutationId
    entity {
      id: dbId
    }
  }
}`)

@graphql(gql`mutation updateEntity($updateEntityMutation: UpdateEntityInput!) {
  updateEntity(input: $updateEntityMutation) {
    clientMutationId
  }
}`, {
  name: 'updateEntity',
  options: {
    refetchQueries: ['OrganizationEntitiesQuery', 'OrganizationGroupsQuery']
  }
})
@observer
export default class EntityAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add person');
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client, params } = nextProps;

    if ((data.loading && !data.node) || !data.node) return;

    const { totalCount, nodes } = data.node.groups;

    if (totalCount === nodes.length) return;

    try {
      const response = await client.query({
        query: gql`
          query EntityAddGroupsQuery($id: ID!, $limit: Int) {
            node (id: $id) {
              ... on Organization {
                id: dbId
                groups (limit: $limit) {
                  nodes {
                    type
                    name
                    id: dbId
                  }
                }
              }
            }
          }`,
        variables: {
          id: params.organization_id,
          limit: totalCount
        }
      });


      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  request = (values) => {
    const { store, api } = this.props;
    const { location } = this.props;
    const query = { ...location.query };
    const entityValues = omit(values, 'picture', Object.keys(query));
    const picture = values.picture;

    return this.props.mutate({
      variables: {
        createEntityMutation: {
          organizationId: this.props.params.organization_id,
          updateIfExists: false,
          ...entityValues
        }
      }
    }).then(async (res) => {
      if (picture) {
        await api.upload('picture', {
          file: picture,
          query: createPicture(res.data.createEntity.entity.id)
        })
          .then(async () => this.updateEntity({ values: entityValues, id: res.data.createEntity.entity.id }))
          .catch((err) => {
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
          });
      } else {
        return this.updateEntity({ values: entityValues, id: res.data.createEntity.entity.id });
      }
    }).catch((err) => {
      this.setState({ errors: { fullname: utils.handleError(err.graphQLErrors[0]) } });
      throw err;
    });
  };

  updateEntity = ({ values, id }) =>
    this.props.updateEntity({
      variables: {
        updateEntityMutation: {
          id,
          fullname: values.fullname,
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('%s was added', values.fullname), success: true };
      this.props.router.goBack();
    }).catch((err) => {
      this.setState({ errors: { fullname: utils.handleError(err.graphQLErrors[0]) } });
    });


  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;
    return (
      <Controller
        id="EntityAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add person')}
        submitButtonIcon="check"
        form={EntityForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}

        submitButton={{
          text: __('Add'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}

        formProps={{
          organization: {
            id: params.organization_id,
            type: data.node.type,
            groups: data.node.groups.nodes,
            tags: data.node.tags.nodes,
            features: data.node.features,
            confCharge: data.node.confCharge,
            plan: data.node.plan,
            premiumPeriod: data.node.premiumPeriod,
            monthlyMessagesCount: data.node.monthlyMessagesCount,
            monthlyMessageQuota: data.node.monthlyMessageQuota
          }
        }}
        {...this.props}
      />
    );
  }
}
