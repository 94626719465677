import React from 'react';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import {
  Button,
  Icon,
  Form,
  Dropdown,
  Divider,
  Step,
  Popup,
  Accordion,
  Table,
  Message,
  Label,
  Input,
  Item,
  Header,
  Image,
  Grid,
  Segment,
  Statistic,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { uniqWith } from 'lodash';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import parse from '../../lib/parse';
import * as utils from '../../utils';
import PhoneInput from '../../components/PhoneInput';
import SampleData from '../../components/SampleData';
import Modal from '../../components/Modal';
import ColoredCheckbox from '../../components/ColoredCheckbox';

const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

const entitiesDiffQuery = gql`
query EntitiesDiffQuery ($organizationId: Int!, $entityType: EntityType!, $newData: String!) {
  entitiesDiff(organizationId: $organizationId, entityType: $entityType, newData: $newData)

  }
`;

@graphql(gql`mutation importChanges($importChangesMutation: ImportChangesInput!) {
  importChanges(input: $importChangesMutation) {
    clientMutationId
  }
}`,
)

@inject('store', 'api', 'client') @observer
export default class EntityImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.route.type,
      string: '',
      file: null,
      countryCode: '+55',
      phonePrefix: '',
      array: null,
      headers: null,
      entities: null,
      errors: null,
      duplicates: null,
      import: false,
      attach: true,
      title: props.route.type === 'staff' ? __('Import/update staff') :
        (utils.checkIfItIsClientOrSchool(props.store.currentOrganization.type) === 1 ?
          __('Import/update students')
          :
          __('Import/update clients')),
      confirmAccordion: {},
      updatedUsersAccordion: false,
      newUsersAccordion: false,
      notFoundUsersAccordion: false,
      shouldDisableNotFound: false,
      confirmed: false,
      notFoundUsers: null,
      diffResponse: null,
      diffError: false,
      diffErrorMsg: '',
      importError: false,
      importFinished: false,
      beforeImport: null,
      showCreatedUsersTable: false,
      showUpdatedUsersTable: false,
      showDisabledUsersTable: false,
      showActiveUsersTable: false,
      selected: __('WinLatin (Default)'),
      loading: false,
    };
  }

  onCancel = () => {
    if (this.state.diffResponse) {
      this.setState({ diffResponse: null, confirmed: null, entities: null, beforeImport: null, checkedDuplicates: true });
    } else if (this.state.duplicates) {
      this.setState({ duplicates: null, checkedDuplicates: false });
    } else if (this.state.errors) {
      this.setState({ errors: null });
    } else if (this.state.array) {
      this.setState({ array: null });
    } else if (this.state.import) {
      this.setState({ import: false });
    } else {
      this.props.router.goBack();
    }
  }

  onClose = async () => {
    await this.props.client.resetStore();
    this.props.router.push(`/organizations/${this.props.store.currentOrganization.id}/entities`);
  }

  onSubmit = async () => {
    if (this.state.importFinished) {
      return this.onClose();
    } else if (this.state.diffResponse) {
      this.setState({ confirmed: true });
      return this.onSubmitEntities();
    } else if (this.state.duplicates) {
      return this.onSubmitUniqueArray();
    } else if (this.state.errors) {
      const array = this.state.array.filter(item => item !== null);
      return this.setState({ array }, () => {
        this.setState({ duplicates: this.onSubmitFixedArray(array) });
      });
    } else if (this.state.array) {
      return this.onSubmitArray();
    }

    return this.onSubmitRaw();
  }

  onSubmitRaw = () => {
    if (this.state.file) {
      const r = new FileReader();
      const { selected } = this.state;

      r.onload = (e) => {
        this.parseData(e.target.result, true);
      };

      if (selected && selected === 'UTF-8') {
        r.readAsText(this.state.file, selected);
      } else {
        r.readAsText(this.state.file, 'ISO-8859-1');
      }
    } else {
      this.parseData(this.state.string);
    }
  }

  onSubmitArray = () => {
    let { array } = this.state;
    const { headers, countryCode } = this.state;

    array = parse.joinUniqueKeys(array, headers);

    const errors = parse.findErrors(array, headers, countryCode);

    this.setState({ array, errors });
  }

  onSubmitFixedArray = () => {
    const { array, headers } = this.state;

    const duplicates = parse.findDuplicates(array, headers);
    return duplicates;
  }

  onSubmitUniqueArray = () => {
    const { type, array, headers, duplicates, countryCode, checkedDuplicates } = this.state;
    let newArray = array.slice();
    let changed = 0;
    if (!checkedDuplicates) {
      Object.keys(duplicates).forEach((i) => {
        Object.keys(duplicates[i].modified).forEach((key) => {
          newArray[key] = duplicates[i].modified[key];
        });

        if (typeof duplicates[i].final !== 'string') {
          newArray[i] = duplicates[i].final;
        } else if (Object.keys(duplicates[i].modified).length) {
          changed++;
        }

        duplicates[i].removed.forEach((j) => {
          newArray[j] = null;
        });
      });
    }
    this.setState({ checkedDuplicates: true });

    newArray = newArray.filter(row => row !== null);

    this.setState({ array: newArray }, () => {
      let newDuplicates = {};

      if (changed) {
        newDuplicates = this.onSubmitFixedArray();
      }

      const conflicts = Object.keys(newDuplicates).map((key) => {
        const newValues = Object.values(newDuplicates[key].items);
        const oldValues = duplicates[key] && Object.values(duplicates[key].items);
        return duplicates[key] && newValues.filter(value => oldValues.indexOf(value) === -1).length > 0;
      }).filter(conflict => conflict).length;

      if (!changed || !conflicts) {
        let entities = parse.arrayToJSON(newArray, headers, countryCode, type, this.columns(type));
        const organizationId = this.props.store.currentOrganization.id;

        entities = entities.map(item => this.parseEntity(item, organizationId));

        this.setState({ entities }, async () => {
          await this.getEntitiesDiff();
        });
      } else {
        this.setState({ duplicates: newDuplicates });
      }
    });
  }

  onSubmitEntities = () => {
    const { diffResponse, shouldDisableNotFound } = this.state;
    const { mutate } = this.props;

    if (shouldDisableNotFound) {
      diffResponse.list.forEach((item) => {
        if (!item.insert && !item.found && item.disabled === 0) {
          item.disable = true;
        }
      });
    }

    const list = diffResponse.list.filter(item => item.insert || item.update || item.disable || item.enable
      || item.manualDisabled || item.manualEnabled || item.updateGroups || item.updateAddress);

    if (!list.length) {
      this.setState({ importFinished: true });
      return;
    }

    const hasToUpdate = {
      list
    };

    return mutate({
      variables: {
        importChangesMutation: {
          organizationId: this.props.store.currentOrganization.id,
          entityType: this.state.type.toUpperCase(),
          hasToUpdate
        }
      }
    }).then(() => {
      this.setState({ importFinished: true });
    }).catch(() => {
      this.setState({ importError: true });
    }
    );
  }

  onDrop = (accepted, rejected) => {
    const { store } = this.props;

    if (rejected.length && rejected[0].size <= ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB && rejected[0].name.indexOf('.csv') > -1) {
      accepted.push(rejected[0]);
    } else if (rejected.length) {
      store.snackbar = {
        active: true,
        message: (rejected[0].size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB) ? __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB) :
          __('File format not accepted, please provide a csv file.'),
        timeout: 8000,
        success: false
      };
      return;
    }

    this.setState({ file: accepted[0] });
  }

  onHeaderChange = i => (e, data) => {
    const { headers, type } = this.state;

    if (this.columnsOptions(this.state.type).indexOf(data.value) === -1 && headers[i].column !== data.value) {
      this.props.store.snackbar = { active: true, message: __("You can't choose this option twice"), success: false };
      return;
    }

    const { currentOrganization } = this.props.store;
    if (data.value === 'email' || data.value === 'phone') {
      headers[i] = {
        column: 'address',
        parameters: {
          type: data.value,
          tags: type !== 'student' || !currentOrganization.tags.nodes.length ? [] : (
            currentOrganization.tags.nodes.length > 1 ? [] : [currentOrganization.tags.nodes[0].name]
          )
        }
      };
    } else {
      headers[i] = { column: data.value };
    }
    this.setState({ headers });
  }

  onTagChange = i => (e, data) => {
    const { headers } = this.state;
    headers[i].parameters.tags = data.value;
    this.setState({ headers });
  }

  onApplyPrefix = () => {
    const { phonePrefix, headers, errors } = this.state;
    let array = this.state.array;

    if (!isNaN(phonePrefix)) {
      array = parse.applyPrefix(phonePrefix, array, headers, errors);
      this.setState({ phonePrefix: '', array, errors: null }, () => this.onSubmitArray());
    } else {
      this.props.store.snackbar = { active: true, message: __('Please enter a valid prefix'), success: false };
    }
  }

  onBlurInput = (col, rowI, i, j = null, value = '') => (e) => {
    const array = this.state.array.slice();
    const val = (e.target.value && e.target.value.replace(/\s+/g, ' ').trim()) || value;
    if (j !== null) {
      array[rowI][i][j] = val;
    } else {
      array[rowI][i] = val;
    }

    const errors = this.checkErrors(col, rowI, i, j, val);
    this.setState({ array, errors });
  }

  onBlurDuplicatedInput = (k, j, i) => (e) => {
    const { duplicates, array, headers } = this.state;
    const duplicate = duplicates[i];

    if (!duplicates[i].modified[j]) {
      duplicates[i].modified[j] = array[j];
    }

    duplicates[i].modified[j][k] = e.target.value.replace(/\s+/g, ' ').trim();

    const toValidate = Object.values(duplicate.items).map(key => duplicate.modified[key] || array[key]);
    const final = parse.validateDuplicates(headers[k].column, k, toValidate);

    if (final) {
      duplicates[i].final = 'changed';
    }

    this.setState({ duplicates });
  }

  getEntitiesDiff = async () => {
    const { entities, type } = this.state;
    const { client } = this.props;

    client.query({
      query: entitiesDiffQuery,
      variables: {
        organizationId: this.props.store.currentOrganization.id,
        entityType: type.toUpperCase(),
        newData: JSON.stringify(entities),
      }
    })
      .then((response) => {
        const diffResponse = JSON.parse(response.data.entitiesDiff);
        const beforeImport = diffResponse.list.filter(item => !item.insert && !item.disabled && !item.manualEnabled && !item.enable).length + diffResponse.list.filter(item => item.manualDisabled).length;
        this.setState({ diffResponse, beforeImport });
      })
      .catch((e) => {
        const msg = JSON.parse(e.message.replace('GraphQL error: ', ''));
        this.setState({ diffError: true, diffErrorMsg: msg });
      }
      );
  }

  getTypeNames = () => ({
    student: utils.checkIfItIsClientOrSchool(this.props.store.currentOrganization.type) === 1 ? __('Student') : __('Client'),
    staff: __('Staff'),
    admin: __('Admin')
  })

  sameLengthRows = (array) => {
    const length = array[0].length;
    for (let i = 0; i < array.length; i++) {
      if (length !== array[i].length) return false;
    }
    return true;
  }

  columns = type => (
    type === 'student' ? [
      { text: '', value: '', options: [] },
      { text: __('Fullname'), value: 'fullname', options: ['fullname', 'nome'] },
      { text: __('Group'), value: 'group', options: ['group', 'class', 'grupo', 'grupos', 'turma', 'classe', 'sala'] },
      { text: __('Email'), value: 'email', options: ['email', 'e-mail'] },
      { text: __('Phone'), value: 'phone', options: ['phone', 'telefone', 'celular'] },
      { text: __('External ID'), value: 'eid', options: ['eid', 'rm', 'ra', 'número de matrícula', 'matrícula', 'id externo'] },
      { text: __('Description'), value: 'description', options: ['description', 'descrição'] },
      { text: __('Disabled'), value: 'disabled', options: ['disabled', 'disable', 'desabilitado', 'desabilitar'] },
      { text: __('Ignore'), value: 'ignore', options: ['ignore', 'ignorar'] }
    ]
      : [
        { text: '', value: '', options: [] },
        { text: __('Fullname'), value: 'fullname', options: ['fullname', 'nome'] },
        { text: __('Group'), value: 'group', options: ['group', 'class', 'grupo', 'grupos', 'turma', 'classe', 'sala'] },
        { text: __('Email'), value: 'email', options: ['email'] },
        { text: __('Phone'), value: 'phone', options: ['phone', 'telefone', 'celular'] },
        { text: __('External ID'), value: 'eid', options: ['eid', 'rm', 'ra', 'número de matrícula', 'matrícula', 'id externo'] },
        { text: __('Description'), value: 'description', options: ['description', 'descrição'] },
        { text: __('Invisible'), value: 'invisible', options: ['invisible', 'invisível'] },
        { text: __('See all'), value: 'see_all', options: ['see_all', 'seeAll', 'see all', 'ver todos'] },
        { text: __('Disabled'), value: 'disabled', options: ['disabled', 'disable', 'desabilitado', 'desabilitar'] },
        { text: __('Ignore'), value: 'ignore', options: ['ignore', 'ignorar'] }
      ])

  columnsOptions = (type) => {
    const headers = this.state.headers && this.state.headers.map(obj => obj.column);
    let options = (type === 'student' ? ['fullname', 'eid', 'description', 'disabled'] :
      ['fullname', 'eid', 'description', 'invisible', 'see_all', 'disabled']);

    if (headers) {
      options = options.filter(option => headers.indexOf(option) === -1);
    }

    return ['', 'group', 'email', 'phone', 'ignore'].concat(options);
  }

  columnNames = {
    fullname: __('Fullname'),
    group: __('Group'),
    eid: __('External ID'),
    invisible: __('Invisible'),
    see_all: __('See all'),
    disabled: __('Disabled'),
    description: __('Description')
  }

  mergeAllDuplicates = () => {
    const { array, duplicates, headers } = this.state;
    array.forEach((row, i) => {
      if (!row || !(i in duplicates) || duplicates[i].final) {
        return;
      }

      const duplicate = duplicates[i];
      const originalRows = array.filter((s, k) => duplicate.items.indexOf(k) > -1);
      const final = parse.mergeAllRows(headers, originalRows);
      duplicates[i].final = final;
      duplicates[i].removed.push(...duplicates[i].items.filter((v, it) => it > 0));
    });

    this.setState({ duplicates });
  }

  /**
   * @param {string[][]} array
   * @param {number | null} i
   */
  parseHeaders = (array, i = null) => {
    const { type } = this.state;

    const first = array[i];

    const headers = parse.headers(first, true, this.columns(type));
    if (headers.filter(header => header.column.length).length) {
      array[i] = null;
    }

    array = array.map(row => (!!row && !!row.filter(cell => !!cell).length ? row : null));

    if (!array.filter(row => !!row).length) {
      this.props.store.snackbar = { active: true, message: __('Input must have at least one row of content'), success: false };
    } else {
      this.setState({ array, headers });
    }
  }

  parseData = (data, file) => {
    /** @type {string[][]} */
    let array;

    try {
      array = parse.csvToArray(data);
    } catch (e) {
      let message = file ? __('File could not be parsed, please provide a well formatted csv file.') :
        __('Pasted content could not be parsed, please provide a well formatted content.');

      if (e.name) { message = e.message; }

      this.props.store.snackbar = { active: true, message, success: false, dismissAfter: 5000 };

      return;
    }

    while (array[0].length === 0 || array[0] == '') {
      array.shift();
    }

    if (!this.sameLengthRows(array)) {
      this.setState({
        columnLengthError: true,
        array: null
      });
    } else {
      this.setState({ columnLengthError: false });
      this.parseHeaders(array, 0);
    }
  }

  checkErrors = (col, rowI, i, j, val) => {
    const errors = { ...this.state.errors };
    const toCheck = ['address', 'fullname', 'eid', 'disabled', 'invisible', 'see_all'];
    if (!errors[rowI]) { errors[rowI] = {}; }
    const fullnameAndEidCell = Object.keys(errors[rowI]).filter(columnIndex => ['eid', 'fullname'].includes(this.state.headers[columnIndex].column));
    if (toCheck.indexOf(col.column) !== -1) {
      let valid = true;

      if (col.parameters && val) {
        valid = col.parameters.type === 'email' ? parse.validateEmail(val) :
          parse.validatePhone(this.state.countryCode, val);
      } else if (val) {
        valid = val;
      }

      if (j === null) {
        if (!valid && !errors[rowI][i]) {
          errors[rowI][i] = [i];
        } else if (col.column === 'fullname' || col.column === 'eid') {
          if (fullnameAndEidCell.length === 1) {
            if (!val) {
              errors[rowI][i] = [j];
            }
          }
          if (val) {
            fullnameAndEidCell.forEach((index) => { delete errors[rowI][index]; });
          }
        } else if (valid) {
          const errorIndex = errors[rowI][i];

          if (errorIndex) {
            delete errors[rowI][i];
          }
        }
      } else if (!valid && !errors[rowI][i]) {
        errors[rowI][i] = [j];
      } else if (!valid && errors[rowI][i] && errors[rowI][i].indexOf(j) === -1) {
        errors[rowI][i].push(j);
      } else if (valid && errors[rowI][i]) {
        if (errors[rowI][i].indexOf(j) !== -1 && errors[rowI][i].length === 1) {
          delete errors[rowI][i];
        } else {
          errors[rowI][i] = errors[rowI][i].filter(index => index !== j);
        }
      }
    }

    return errors;
  }

  parseEntity = (entity, organizationId) => {
    const input = { ...entity, organizationId, type: entity.type.toUpperCase(), attach: this.state.attach };

    if (input.groups) {
      input.groups = input.groups.map(group => ({ name: group }));
    } else {
      input.groups = [];
    }

    if (input.addresses) {
      input.addresses = input.addresses.filter(address => typeof address.address === 'string').map(address => ({
        address: address.address,
        type: address.type.toLowerCase(),
        tags: address.tags.map(tag => ({ name: tag }))
      }));
    } else {
      input.addresses = [];
    }

    return input;
  }


  handleUserTableRowRemoval(user, origin) {
    const { diffResponse } = this.state;
    const item = diffResponse.list.find(element => user.eid === element.eid && user.fullname === element.fullname);

    switch (origin) {
      case 'reactivatedUsers':
        delete item.enable;
        delete item.manualEnabled;
        item.disabled = 1;
        break;
      case 'updatedUsers':
        delete item.update;
        delete item.updateAddress;
        delete item.updateGroups;
        item.addresses = item.addresses.filter(addr => !addr.insert);
        item.groups = item.groups.filter(group => !group.insert || !group.update);
        break;
      case 'newUsers':
        const index = diffResponse.list.findIndex(element => user.eid === element.eid && user.fullname === element.fullname);
        diffResponse.list.splice(index, 1);
        break;
      case 'manuallyDisabledUsers':
        delete item.manualDisabled;
        item.disabled = 0;
        break;
      default:
    }
    this.setState({ diffResponse });
  }

  renderInputCell = (cell, rowI, i) => {
    if (Array.isArray(cell)) {
      return cell.map((val, j) => this.renderInput(val, rowI, i, j));
    }
    return this.renderInput(cell, rowI, i);
  }

  renderInput = (value, rowI, i, j = null) => {
    const errors = this.state.errors[rowI] && this.state.errors[rowI][i];
    const col = this.state.headers[i];
    const final = value;
    let error = false;
    let errorMessage = null;

    if (errors && (j === null || errors.indexOf(j) !== -1)) {
      if (col.parameters) {
        error = true;
        errorMessage = col.parameters.type === 'email' ?
          __('Check domain name and @ sign') :
          __('Check area code and number length');
      } else if (['disabled', 'invisible', 'see_all'].includes(col.column)) {
        error = true;
        errorMessage = __('Only these entries are valid: yes, no, 0 or 1. Please confirm your choice.');
      } else {
        error = true;
        errorMessage = __('This field cannot be empty');
      }
    }

    if (['see_all', 'invisible', 'disabled'].indexOf(col.column) > -1) {
      return ([
        <ColoredCheckbox
          checked={parse.parseBoolean(value)}
          onClick={() => {
            const array = this.state.array.slice();
            const bool = parse.parseBoolean(value) ? '0' : '1';

            if (j !== null) {
              array[rowI][i][j] = bool;
            } else {
              array[rowI][i] = bool;
            }

            const err = this.checkErrors(col, rowI, i, j, bool);

            this.setState({ array, errors: err });
          }}
        />,
        errorMessage && <Popup
          trigger={<Button icon="exclamation triangle" basic color="red" />}
          content={errorMessage}
        />
      ]);
    }

    return (
      <Input
        action={final || errorMessage}
        key={j !== null ? j + final : i + final}
        error={error}
        defaultValue={final}
        label={col.column === 'address' && col.parameters.type === 'phone'}
        onBlur={this.onBlurInput(col, rowI, i, j)}
      >
        {col.column === 'address' && col.parameters.type === 'phone' && <Label content={this.state.countryCode} />}
        <input />
        {final && <Button data-action="delete" icon="times" basic color={errorMessage ? 'red' : undefined} onClick={this.onBlurInput(col, rowI, i, j, '')} />}
        {errorMessage && <Popup
          trigger={<Button icon="exclamation triangle" basic color="red" />}
          content={errorMessage}
        />}
      </Input>
    );
  }

  renderInputColumns = () =>
    this.state.headers.map((header, i) => {
      const dropdown = defaultValue => (
        <Dropdown
          selection
          key={i}
          placeholder={__('Choose an option')}
          options={this.columns(this.state.type)}
          value={defaultValue}
          onChange={this.onHeaderChange(i)}
        />
      );

      if (header.column === 'address') {
        const { currentOrganization } = this.props.store;
        let tags;
        if (currentOrganization.tags.nodes) {
          tags = this.props.store.currentOrganization.tags.nodes.map(tag => ({ text: tag.name, value: tag.name }));
        } else if (currentOrganization.type === 'SCHOOL') {
          tags = [
            { text: __('Student'), value: __('Student') },
            { text: __('Parent/Guardian'), value: __('Parent/Guardian') }
          ];
        }
        return (
          <div>
            {dropdown(header.parameters.type)}
            {
              tags &&
              <Dropdown
                selection
                multiple
                search
                className="tags"
                noResultsMessage={__('No results were found')}
                placeholder={this.state.type === 'student' ? __('Tags (required)') : __('Tags')}
                options={tags}
                value={header.parameters.tags}
                onChange={this.onTagChange(i)}
              />
            }
          </div>
        );
      }

      return dropdown(header.column);
    })

  renderFixedColumns = () => this.state.headers.map((header, i) => {
    if (header.column === 'address') {
      return (
        <Item
          key={i}
          description={
            header.parameters.type === 'email' ? __('Email') : __('Phone')
          }
          extra={header.parameters.tags.map((tag, j) => <Label key={j}>{tag}</Label>)}
        />
      );
    }
    return this.columnNames[header.column];
  })

  renderDeleteRow = i =>
    <Popup
      inverted
      content={__('Delete row')}
      trigger={
        <Button
          data-action="delete"
          basic
          size="mini"
          icon="delete" onClick={() => {
            const array = this.state.array.slice();
            array[i] = null;
            const errors = this.state.errors;
            if (errors && errors[i]) {
              delete errors[i];
            }
            this.setState({ array }, () => {
              if (!this.state.errors) {
                const index = array.map((val, j) => val && j).filter(val => val)[0];
                if (array && (i < index) && (!this.state.headers.find(header => header.column))) {
                  this.parseHeaders(array, index);
                }
              }
            });
          }}
        />
      }
    />

  erasedField = (array, original) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === '' && original && original[i] !== '') {
        return true;
      }
    }
    return false;
  }

  renderErrorRows = () => {
    const { array, errors, headers, string } = this.state;
    const original = parse.csvToArray(string);

    return array.map((row, i) => {
      if (!row || (!(i in errors) && !this.erasedField(row, original[i]))) {
        return null;
      }

      const positive = errors && ((Object.keys(errors[i] || {}).length === 0));
      return (
        <Table.Row key={i} positive={positive}>
          <Table.Cell>
            {
              !positive ?
                <Popup
                  trigger={<Icon name="exclamation triangle" color="red" />}
                  content={__('There are errors in this row')}
                /> :
                <Popup
                  trigger={<Icon name="check" color="green" />}
                  content={__('All errors in this row are fixed')}
                />
            }
          </Table.Cell>
          <Table.Cell>{this.renderDeleteRow(i)}</Table.Cell>
          {
            row && row.map((cell, j) => {
              if (headers[j].column === 'ignore') return null;
              return <Table.Cell key={j}>{this.renderInputCell(cell, i, j)}</Table.Cell>;
            })
          }
        </Table.Row>
      );
    });
  }

  renderBasicRows = () => {
    const { array } = this.state;

    return array.map((row, i) => {
      if (!row) return null;

      return (
        <Table.Row key={i}>
          <Table.Cell>{this.renderDeleteRow(i)}</Table.Cell>
          {
            row && row.map((cell, j) =>
              <Table.Cell key={j}>
                {Array.isArray(cell) ? cell.map(val => <Label basic content={val} />) : cell}
              </Table.Cell>
            )
          }
        </Table.Row>
      );
    });
  }

  renderDuplicateRows = () => {
    const { array, duplicates, headers } = this.state;

    const colSpan = headers.length + 2;

    return array.map((row, i) => {
      if (!row || !(i in duplicates)) {
        return null;
      }

      const duplicate = duplicates[i];

      if (duplicate.final && typeof duplicate.final !== 'string') {
        return (
          <Table.Row positive key={i}>
            <Table.Cell />
            <Table.Cell icon="check" />
            {duplicate.final.map((cell, k) => {
              if (headers[k].column === 'ignore') return null;
              return this.renderCell(cell, k);
            })}
          </Table.Row>
        );
      } else if (duplicate.final && duplicate.final === 'changed') {
        return (Object.values(duplicate.modified).map((item, k) => (
          <Table.Row positive key={[i, k].join(',')}>
            <Table.Cell />
            <Table.Cell icon="check" />
            {item.map((cell, j) => {
              if (headers[j].column === 'ignore') return null;
              return this.renderCell(cell, j);
            })}
          </Table.Row>
        )));
      } else if (duplicate.final && duplicate.final === 'mantained') {
        return (duplicate.items.map((item, j) =>
          <Table.Row key={j} positive>
            <Table.Cell />
            <Table.Cell icon="check" />
            {array[item] ? array[item].map((cell, k) => {
              if (headers[k].column === 'ignore') return null;
              return this.renderCell(cell, k);
            }) : null}
          </Table.Row>
        ));
      }

      const expandButton = (
        <Button
          data-action="toggle"
          basic
          size="mini"
          icon={duplicate.expanded ? 'chevron up' : 'chevron down'}
          onClick={() => {
            duplicates[i].expanded = !duplicates[i].expanded;
            this.setState({ duplicates });
          }}
        />
      );

      const eidIndex = headers.findIndex(header => header.column === 'eid');
      const fullnameIndex = headers.findIndex(header => header.column === 'fullname');
      const uniqueIndex = eidIndex > -1 ? eidIndex : fullnameIndex;

      if (duplicate.expanded) {
        return ([
          <Table.Row key={'header-' + i}>
            <Table.Cell colSpan={colSpan} />
          </Table.Row>,
          duplicate.items.map((item, j) =>
            <Table.Row key={j} warning>
              <Table.Cell>
                {j === 0 && expandButton}
              </Table.Cell>
              <Table.Cell>
                <Popup
                  inverted
                  content={__('Delete row')}
                  trigger={
                    <Button
                      data-action="delete"
                      basic
                      size="mini"
                      icon="delete" onClick={() => {
                        duplicates[i].removed.push(duplicates[i].items[j]);
                        duplicates[i].items.splice(j, 1);
                        if (duplicates[i].items.length === 1) {
                          duplicates[i].final = array[duplicates[i].items[0]];
                        }
                        this.setState({ duplicates });
                      }}
                    />
                  }
                />
              </Table.Cell>
              {array[item].map((cell, k) => {
                if (headers[k].column === 'ignore') return null;
                if (k !== uniqueIndex) {
                  return this.renderCell(cell, k);
                }
                return this.renderDuplicatedCell(cell, k, duplicate.items[j], i);
              })}
            </Table.Row>
          ),
          <Table.Row key={'footer-' + i}>
            <Table.Cell colSpan={colSpan}>
              <Button
                data-action="merge"
                content={__('Merge')}
                onClick={() => {
                  const originalRows = array.filter((s, k) => duplicate.items.indexOf(k) > -1);
                  const final = parse.mergeAllRows(headers, originalRows);
                  duplicates[i].final = final;
                  duplicates[i].removed.push(...duplicates[i].items.filter((v, it) => it > 0));
                  duplicates[i].items = [duplicates[i].items[0]];
                  this.setState({ duplicates });
                }}
              />
              <Button
                data-action="ignore"
                content={__('Ignore')}
                onClick={() => {
                  const toValidate = Object.values(duplicate.items).map(key => duplicate.modified[key] || array[key]);
                  const final = parse.validateDuplicates(headers[uniqueIndex].column, uniqueIndex, toValidate, 1);
                  if (final) {
                    duplicates[i].final = 'mantained';
                  } else {
                    this.props.store.snackbar = { active: true, message: __('These rows cannot be equal'), success: false };
                  }
                  this.setState({ duplicates });
                }}
              />
            </Table.Cell>
          </Table.Row>
        ]);
      }

      return (
        <Table.Row key={i}>
          <Table.Cell>
            {expandButton}
          </Table.Cell>
          <Table.Cell>
            <Popup
              trigger={<Icon name="exclamation triangle" color="yellow" />}
              content={__('There are duplicates')}
            />
          </Table.Cell>
          {
            row && row.map((cell, j) => {
              if (headers[j].column === 'ignore') return null;
              return this.renderCell(cell, j);
            })
          }
        </Table.Row>
      );
    });
  }

  renderCell = (cell, i) => <Table.Cell key={i}>{Array.isArray(cell) ? cell.join(', ') : cell}</Table.Cell>

  renderDuplicatedCell = (cell, k, j, i) => <Table.Cell key={Math.random()}>
    <Input
      defaultValue={cell}
      onBlur={this.onBlurDuplicatedInput(k, j, i)}
    />
  </Table.Cell>

  renderUploadForm = () => {
    const { file, string, columnLengthError, selected } = this.state;
    const charsetOptions = [
      {
        key: __('WinLatin (Default)'),
        text: __('WinLatin (Default)'),
        value: __('WinLatin (Default)')
      },
      {
        key: 'UTF-8',
        text: 'UTF-8',
        value: 'UTF-8'
      }
    ];

    return (
      <div>
        <Grid>
          {
            columnLengthError ? (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Message error>
                    <Message.Header>{__('Error in number of columns')}</Message.Header>
                    <p>{__('Rows must have the same number of columns')}</p>
                  </Message>
                </Grid.Column>
              </Grid.Row>)
              : (
                <Grid.Row columns={2}>
                  <Grid.Column>
                    {
                      (file !== null || string !== '') ?
                        null
                        :
                        <Button
                          data-action="open-example"
                          icon="table"
                          content={__('Show example')}
                          onClick={() => this.props.store.appends.push(<SampleData entityType={this.props.route.type.toUpperCase()} />)}
                        />
                    }
                  </Grid.Column>
                  <Grid.Column>
                    {
                      null
                    }
                  </Grid.Column>
                </Grid.Row>)
          }
          <Divider hidden fitted />
        </Grid>
        {
          (file !== null || string !== '') ?
            <Message
              icon="check"
              content={<div>
                {
                  string !== '' ?
                    <Form style={{ padding: '2em', paddingBottom: '1em', paddingTop: '1em' }}>
                      <Form.Field style={{ position: 'relative' }}>
                        <Form.TextArea
                          rows="4"
                          placeholder={__('Copy and paste your spreadsheet content here')}
                          value={this.state.string}
                          onChange={e => this.setState({ string: e.target.value })}
                        />
                      </Form.Field>
                    </Form>
                    :
                    <div style={{ display: 'inline-grid' }}>
                      <strong style={{ fontSize: '16px' }}>{string !== '' ? __('Content uploaded') : __('File uploaded')}</strong>
                      <div style={{ display: 'inline-block', marginTop: '10px' }}>
                        <strong>{__('File encoding: ')}</strong>
                        <span style={{ color: '#4a90e2', margin: '5px 0px 0px 5px' }}>
                          <Dropdown
                            inline
                            scrolling
                            options={charsetOptions}
                            defaultValue={selected}
                            onChange={event => this.setState({ selected: event.target.textContent })}
                          />
                        </span>
                        <Popup
                          trigger={<Icon style={{ marginLeft: '5px' }} name="info circle" color="grey" />}
                          content={__('The encoding indicates the set of characters used in the worksheet. Change this field if you encounter problems with the characters and accentuation of the spreadsheet data.')}
                          basic
                        />
                      </div>
                    </div>
                }
                <Button data-action="delete" style={{ marginTop: '7px' }} floated="right" onClick={() => this.setState({ file: null, string: '', columnLengthError: false })}>{string !== '' ? __('Clear content') : __('Clear file')}</Button>
              </div>}
            /> :
            <div>
              <Form>
                <Form.Field style={{ position: 'relative' }}>
                  <Popup
                    trigger={
                      <Icon size="large" style={{ position: 'absolute', left: '0.5em', bottom: '0.5em' }} name="question circle" />
                    }
                    content={<Image src={utils.asset('/import/copy_pt.gif')} />}
                  />
                  <Form.TextArea
                    rows="4"
                    placeholder={__('Copy and paste your spreadsheet content here')}
                    onChange={e => this.setState({ string: e.target.value })}
                  />
                </Form.Field>
                <Form.Field>
                  <Divider horizontal>{__('or')}</Divider>
                  <Dropzone
                    ref={(node) => { this.dropzone = node; }}
                    onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                    multiple={false}
                    accept="text/csv,application/csv"
                    maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
                    className="ui button basic fluid large"
                    style={{ border: '2px dashed rgba(34,36,38,.15)' }}
                  >
                    <Icon name="arrow down" circular />
                    <div style={{ color: 'rgba(0,0,0,.5)' }}>{__('Drop file here or click to browse')}</div>
                  </Dropzone>
                </Form.Field>
              </Form>
            </div>
        }
      </div>
    );
  }

  renderUsersTable(users, title, origin = '') {
    const maxLength = Math.max(...(users.map(user => user.addresses.length)));
    const userRows = users.map((user) => {
      const tableFillArray = Array.from(Array(maxLength - user.addresses.length), () => <Table.Cell />);
      return (
        <Table.Row>
          <Table.Cell>
            <Header as="h4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {origin && origin !== 'notFoundUsers' && origin !== 'unchangedUsers' ? <Icon fitted name="delete" style={{ cursor: 'pointer' }} onClick={() => this.handleUserTableRowRemoval(user, origin)} /> : null}
              <Header.Content>
                {user.fullname}
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>
            <Header as="h4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Header.Content>
                {user.eid}
              </Header.Content>
            </Header>
          </Table.Cell>

          {!!user.groups.length &&
            <Table.Cell>
              {user.groups.filter(item => !item.delete).map(group =>
                <Label key={group.id}>{group.name}</Label>
              )}
            </Table.Cell>
          }

          {!!user.addresses.length &&
            user.addresses.filter(item => !item.delete).map(address => <Table.Cell>{address.address}</Table.Cell>)
          }

          {tableFillArray}

        </Table.Row>
      );
    }

    );

    return (
      userRows.length ?
        <div style={{ marginBottom: '20px', maxWidth: '100%' }} >
          <p style={{ textAlign: 'left', fontSize: '15px', fontWeight: 'bold', color: 'gray' }}>{title}</p>
          <div style={{ overflow: 'scroll' }}>
            <Table basic celled collapsing >
              <Table.Body>
                {userRows}
              </Table.Body>
            </Table>
          </div>
        </div> : null
    );
  }

  renderErrorsDiff() {
    const { diffError, diffErrorMsg } = this.state;
    let errorDuplicated;
    let errorIds;
    if (diffError) {
      errorIds = diffErrorMsg.errorsEid.map(row => (row.eid));
      errorDuplicated = diffErrorMsg.errorsDuplicated;
    }

    if (errorIds.length > 0) {
      if (errorIds.length === 1) {
        return <p>&#8226;{__('No user was found with the eid ') + errorIds[0]}.</p>;
      }
      return <p>&#8226;{__('No users were found with the eids ') + errorIds.map(id => id)}.</p>;
    }

    if (errorDuplicated.length > 0) {
      return this.renderUsersTable(errorDuplicated, __('There are two or more entities with same name and different eids'));
    }
    return null;
  }

  renderConfirm = () => {
    const { diffResponse, diffError, shouldDisableNotFound, beforeImport, type } = this.state;

    if (diffResponse !== null) {
      const reactivadedUsers = diffResponse.list.filter(item => item.enable || item.manualEnabled);
      const unchangedUsers = diffResponse.list.filter(item => !item.insert && !item.update && !item.updateAddress && !item.updateGroups && !item.manualDisabled && !item.manualEnabled && !item.enable);
      const updatedUsers = diffResponse.list.filter(item => (item.update || item.updateAddress || item.updateGroups) && !item.insert);
      const newUsers = diffResponse.list.filter(item => item.insert);
      const notFoundUsers = diffResponse.list.filter(item => !item.found && !item.disabled && !item.insert && !item.enable && !item.manualEnabled);
      const foundAndActiveUsers = diffResponse.list.filter(item => item.found && !item.disabled && !item.insert && !item.enable && !item.manualEnabled && !item.manualDisabled);
      const manuallyDisabledUsers = diffResponse.list.filter(item => item.manualDisabled);

      const reactivatedUsersTable = this.renderUsersTable(reactivadedUsers, type === 'student' ? __('Reactivated students').toUpperCase() : __('Reactivated staff').toUpperCase(), 'reactivatedUsers');
      const updatedUsersTable = this.renderUsersTable(updatedUsers, type === 'student' ? __('Updated students').toUpperCase() : __('Updated staff').toUpperCase(), 'updatedUsers');
      const newUsersTable = this.renderUsersTable(newUsers, type === 'student' ? __('New students').toUpperCase() : __('New staff').toUpperCase(), 'newUsers');
      const notFoundUsersTable = this.renderUsersTable(notFoundUsers, type === 'student' ? __('Students that were not found').toUpperCase() : __('Staff that was not found').toUpperCase(), 'notFoundUsers');
      const manuallyDisabledUsersTable = this.renderUsersTable(manuallyDisabledUsers, type === 'student' ? __('Students that were disabled during import').toUpperCase() : __('Staff that was disabled during import').toUpperCase(), 'manuallyDisabledUsers');


      const amountUpdatedUsers = uniqWith([...reactivadedUsers, ...updatedUsers], (e1, e2) => e1.eid === e2.eid && e1.fullname === e2.fullname).length;
      const amountNewUsers = newUsers.length;
      const amountNotFoundUsers = notFoundUsers.length;
      const amountManuallyDisabledUsers = manuallyDisabledUsers.length;

      const enabled = !!notFoundUsers.length;

      const afterImport = (amountNewUsers + uniqWith([...updatedUsers, ...reactivadedUsers, ...unchangedUsers, ...foundAndActiveUsers]
        .filter(item => !item.disabled), (e1, e2) => e1.eid === e2.eid && e1.fullname === e2.fullname).length) - (shouldDisableNotFound && amountNotFoundUsers);

      return (
        <div style={{ flex: 1 }}>
          <Segment style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
            <div style={{ marginRight: '30px' }}>
              <div style={{ fontSize: '15px', display: 'flex', flexDirection: 'row', paddingTop: '25px' }}>
                <Icon name="square" color="blue" className="bold" />
                <p style={{ fontSize: '15px' }} id="BeforeImportLabel" > {__('Before import:')} {beforeImport} </p>
                <Popup
                  trigger={<Icon style={{ opacity: '.6', marginLeft: '10px' }} name="info circle" color="grey" />}
                  content={type === 'student' ? __('Total of students active on ClassApp before import') : __('Total staff active on ClassApp before import')}
                />
              </div>
            </div>
            <div style={{ fontSize: '15px', display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5px' }}>
                <div style={{ paddingTop: '25px', display: 'flex', flexDirection: 'row', marginRight: '15px' }}>
                  <Icon name="square" color="blue" className="bold" />
                  <p style={{ fontSize: '15px', fontWeight: 'bold' }} id="AfterImportLabel" > {__('After import:').toUpperCase()} </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                  <Statistic.Value style={{ fontSize: '40px', fontWeight: 'bold', lineHeight: '1.3' }} id="NumberAfterImport" >{afterImport}</Statistic.Value>
                  <p style={{ textAlign: 'left', fontSize: '15px', fontWeight: 'bold', color: 'gray' }} >{type === 'student' ? (afterImport > 1 ? __('Students').toUpperCase() : __('Student').toUpperCase()) : (afterImport > 1 ? __('Staff (singular)').toUpperCase() : __('Staff (plural)').toUpperCase())}</p>
                </div>
                <div
                  style={{ marginTop: '25px' }}
                >
                  <Popup
                    trigger={<Icon style={{ opacity: '.6', marginLeft: '10px' }} name="info circle" color="grey" />}
                    content={type === 'student' ? __('Total of students active on ClassApp after import') : __('Total staff active on ClassApp after import')}
                  />
                </div>
              </div>
            </div>
          </Segment>
          <Segment>
            <Accordion styled style={{ marginBottom: '20px', backgroundColor: '#E9EAEC', fontColor: 'black' }}>
              <Accordion.Title id="AmountOfUpdatedStudentsLabel" active={this.state.updatedUsersAccordion} onClick={() => this.setState((previousState => ({ updatedUsersAccordion: !previousState.updatedUsersAccordion })))}>
                <Icon name="dropdown" />
                {type === 'student' ? __('Amount of updated students:') : __('Amount of updated staff:')} {amountUpdatedUsers}
                <Popup
                  trigger={<Icon style={{ opacity: '.6', marginLeft: '10px' }} name="info circle" color="grey" />}
                  content={type === 'student' ? __('Total of students updated') : __('Total staff updated')}
                />
                {reactivadedUsers && reactivadedUsers.length > 0 ?
                  <Popup
                    trigger={<Icon style={{ opacity: '.6', marginLeft: '3px' }} name="exclamation triangle" color="yellow" />}
                    content={type === 'student' ? __('Students activated automatically after import') : __('Staff activated automatically after import')}
                  />
                  :
                  null
                }
              </Accordion.Title>
            </Accordion>
            {this.state.updatedUsersAccordion ?
              reactivatedUsersTable : null
            }
            {this.state.updatedUsersAccordion ?
              updatedUsersTable : null
            }

            <Accordion styled style={{ marginBottom: '20px', backgroundColor: '#E9EAEC', color: 'black' }}>
              <Accordion.Title id="AmountOfNewStudentsLabel" active={this.state.newUsersAccordion} onClick={() => this.setState((previousState => ({ newUsersAccordion: !previousState.newUsersAccordion })))}>
                <Icon name="dropdown" />
                {type === 'student' ? __('Amount of new students:') : __('Amount of new staff:')} {amountNewUsers}
                <Popup
                  trigger={<Icon style={{ opacity: '.6', marginLeft: '10px' }} name="info circle" color="grey" />}
                  content={type === 'student' ? __('New students created after import') : __('New staff created after import')}
                />
              </Accordion.Title>
            </Accordion>
            {this.state.newUsersAccordion ?
              newUsersTable : null
            }
            <Accordion styled style={{ marginBottom: '20px', backgroundColor: '#E9EAEC' }}>
              <Accordion.Title id="AmountOfDisabledStudentsLabel" active={this.state.notFoundUsersAccordion} onClick={() => this.setState((previousState => ({ notFoundUsersAccordion: !previousState.notFoundUsersAccordion })))}>
                <Icon name="dropdown" />
                {type === 'student' ? __('Amount of students that were disabled or not found:') : __('Amount of staff that was disabled or not found:')} {amountManuallyDisabledUsers + amountNotFoundUsers}
                <Popup
                  trigger={<Icon style={{ opacity: '.6', marginLeft: '10px' }} name="info circle" color="grey" />}
                  content={type === 'student' ? __("Active students on ClassApp that weren't found after import") : __("Active staff on ClassApp that wasn't found after import")}
                />
              </Accordion.Title>
            </Accordion>
            {this.state.notFoundUsersAccordion ?
              manuallyDisabledUsersTable : null
            }
            {this.state.notFoundUsersAccordion ?
              notFoundUsersTable : null
            }
            {enabled ?
              <ColoredCheckbox checked={this.state.shouldDisableNotFound} onClick={() => this.setState((previousState => ({ shouldDisableNotFound: !previousState.shouldDisableNotFound })))} label={type === 'student' ? __('Deactivate students that were not found') : __('Deactivate staff that was not found')} />
              : null
            }
          </Segment>
        </div>
      );
    }
    return (
      <Segment style={{ justifyContent: 'center', alignContent: 'center', padding: '25px' }}>
        {diffError ?
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: 'auto', textAlign: 'center' }}>
            <Image size="small" style={{ marginRight: '10px' }} src={utils.asset('/images/ilustracoes-31.png')} />
            <p style={{ fontSize: '15px' }} className="bold defaultClspColor"> {__('Import failed because the following error was found')} </p>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center' }}>
              {this.renderErrorsDiff()}
            </div>
          </div>
          :
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        }
      </Segment>
    );
  }

  renderConclusion() {
    const { type, diffResponse, importFinished, importError, shouldDisableNotFound, showCreatedUsersTable, showUpdatedUsersTable, showDisabledUsersTable, showActiveUsersTable } = this.state;

    const reactivadedUsers = diffResponse.list.filter(item => item.enable || item.manualEnabled);
    const updatedUsers = diffResponse.list.filter(item => (item.update || item.updateAddress || item.updateGroups) && !item.insert);
    const unchangedUsers = diffResponse.list.filter(item => !item.insert && !item.update && !item.updateAddress && !item.updateGroups && !item.manualDisabled && !item.manualEnabled);
    const newUsers = diffResponse.list.filter(item => item.insert);
    const notFoundUsers = diffResponse.list.filter(item => !item.found && !item.disabled && !item.insert && !item.enable && !item.manualEnabled);
    const foundAndActiveUsers = diffResponse.list.filter(item => item.found && !item.disabled && !item.insert && !item.enable && !item.manualEnabled);
    const manuallyDisabledUsers = diffResponse.list.filter(item => item.manualDisabled);

    const amountUpdatedUsers = uniqWith([...reactivadedUsers, ...updatedUsers], (e1, e2) => e1.eid === e2.eid && e1.fullname === e2.fullname).length;
    const amountNewUsers = newUsers.length;
    const amountDisabledUsers = !shouldDisableNotFound ? manuallyDisabledUsers.length : manuallyDisabledUsers.length + notFoundUsers.length;
    const amountNotFoundUsers = notFoundUsers.length;

    const amountUsers = (amountNewUsers + uniqWith([...updatedUsers, ...reactivadedUsers, ...unchangedUsers, ...foundAndActiveUsers]
      .filter(item => !item.disabled), (e1, e2) => e1.eid === e2.eid && e1.fullname === e2.fullname).length) - (shouldDisableNotFound && amountNotFoundUsers);

    const updatedUsersTable = this.renderUsersTable(updatedUsers, type === 'student' ? __('Already active students').toUpperCase() : __('Already active staff').toUpperCase());
    const reactivatedUsersTable = this.renderUsersTable(reactivadedUsers, type === 'student' ? __('Reactivated students').toUpperCase() : __('Reactivated staff').toUpperCase());
    const newUsersTable = this.renderUsersTable(newUsers, type === 'student' ? __('New students').toUpperCase() : __('New staff').toUpperCase());
    const disabledUsersTable = this.renderUsersTable([...manuallyDisabledUsers, ...(shouldDisableNotFound ? notFoundUsers : [])], type === 'student' ? __('Students that were disabled during import').toUpperCase() : __('Staff that was disabled during import').toUpperCase());

    let activeUsers = [...updatedUsers, ...reactivadedUsers, ...newUsers, ...unchangedUsers].filter(item => !item.disabled && !item.disable);
    if (!shouldDisableNotFound) {
      activeUsers = [...activeUsers, ...notFoundUsers];
    }

    activeUsers = uniqWith(activeUsers, (e1, e2) => e1.eid === e2.eid && e1.fullname === e2.fullname);

    const activeUsersTable = this.renderUsersTable(activeUsers, type === 'student' ? __('Active students').toUpperCase() : __('Active staff').toUpperCase());

    return (
      <Segment>
        {importError ?
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: 'auto', textAlign: 'center' }}>
              <Image style={{ marginRight: '10px' }} size="small" src={utils.asset('/images/ilustracoes-31.png')} />
              <p style={{ fontSize: '15px' }} className="bold defaultClspColor"> {__('An error occured during the import process. Please try again.')} </p>
            </div>
          </div>
          :
          <div>
            {importFinished ?
              <div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: 'auto' }}>
                        <Image size="small" style={{ marginRight: '10px' }} src={utils.asset('/images/ilustracoes-31.png')} />
                      </div>

                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                        <p style={{ fontSize: '20px', textAlign: 'center' }} className="bold defaultClspColor"> {__('Finished import').toUpperCase()} </p>
                      </div>
                    </div>

                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        <div style={{ display: 'flex', width: '340px', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center' }} className="bold" >
                          <p style={{ fontSize: '15px' }} id="CreatedStudentsLabel" > {type === 'student' ? __('Created students:') : __('Created staff:')} {amountNewUsers}</p>
                          <Icon style={{ cursor: 'pointer' }} name={showCreatedUsersTable ? 'angle up' : 'angle down'} color="gray" onClick={() => this.setState({ showCreatedUsersTable: !showCreatedUsersTable })} />
                        </div>
                      </div>
                    </div>


                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        {showCreatedUsersTable && amountNewUsers ?
                          newUsersTable : null
                        }
                      </div>
                    </div>

                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        <div style={{ display: 'flex', width: '340px', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center' }} className="bold" >
                          <p style={{ fontSize: '15px' }} id="UpdatedLabel" > {type === 'student' ? __('Updated students:') : __('Updated staff:')} {amountUpdatedUsers}</p>
                          <Icon style={{ cursor: 'pointer' }} name={showUpdatedUsersTable ? 'angle up' : 'angle down'} color="gray" onClick={() => this.setState({ showUpdatedUsersTable: !showUpdatedUsersTable })} />
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        {showUpdatedUsersTable && amountUpdatedUsers ?
                          reactivatedUsersTable : null
                        }
                        {showUpdatedUsersTable && amountUpdatedUsers ?
                          updatedUsersTable : null
                        }
                      </div>
                    </div>

                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        <div style={{ display: 'flex', width: '340px', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center' }} className="bold" >
                          <p style={{ fontSize: '15px' }} id="DeactivatedLabel" > {type === 'student' ? __('Deactivated students:') : __('Deactivated staff:')} {amountDisabledUsers} </p>
                          <Icon style={{ cursor: 'pointer' }} name={showDisabledUsersTable ? 'angle up' : 'angle down'} color="gray" onClick={() => this.setState({ showDisabledUsersTable: !showDisabledUsersTable })} />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                        {showDisabledUsersTable && amountDisabledUsers ?
                          disabledUsersTable : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%', backgroundColor: '#E9EAEC' }}>
                    <div style={{ display: 'flex', width: '360px', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', padding: '10px' }} className="bold" >
                      <p style={{ margin: '0 15px 0 0' }} id="ActiveStudentsOnOrganization" >{type === 'student' ? __('Active students on ClassApp:').toUpperCase() : __('Active staff on ClassApp:').toUpperCase()} {amountUsers}</p>
                      <Icon style={{ cursor: 'pointer' }} name={showActiveUsersTable ? 'angle up' : 'angle down'} color="gray" onClick={() => this.setState({ showActiveUsersTable: !showActiveUsersTable })} />
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', maxWidth: '100%' }}>
                    {showActiveUsersTable && amountUsers ?
                      activeUsersTable : null
                    }
                  </div>
                </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ paddingTop: '30px', paddingBottom: '60px' }}>
                  <Dimmer inverted active>
                    <Loader indeterminate />
                  </Dimmer>
                </div>
                <p>{__('This may take a few moments')}</p>
              </div>
            }
          </div>
        }

      </Segment >
    );
  }

  render() {
    const {
      type, file, string, array, headers, errors, duplicates,
      entities, confirmed, importFinished, importError, diffError, attach, loading
    } = this.state;

    let enabled = false;
    let view = null;
    if (type) {
      enabled = (file !== null || string !== '');
      view = this.renderUploadForm();
    }
    if (confirmed) {
      view = this.renderConclusion();
    } else if (entities) {
      view = this.renderConfirm();
    } else if (array) {
      let where = null;
      let pending = null;
      let rows = null;
      let message = null;

      if (duplicates !== null) {
        where = 'duplicates';
        pending = Object.keys(utils.objectFilter(duplicates, duplicate => duplicate.final === null)).length;
        enabled = pending === 0;
        rows = this.renderDuplicateRows();
        message = (
          enabled ?
            <Message success icon="check" header={__('No duplicates')} /> :
            (<Message warning>
              <Popup
                basic
                on="click"
                hideOnScroll
                flowing
                trigger={<Button floated="right" basic icon="cog" />}
                content={
                  <div>
                    <Button
                      data-action="merge-all"
                      primary
                      onClick={() => this.mergeAllDuplicates()}
                      content={__('Merge all rows')}
                    />
                  </div>
                }
              />
              <Message.Header>{__('Number of duplicate rows:') + ' ' + pending}</Message.Header>
              <p>{__('There are some duplicate rows in your table, fix them to continue')}</p>
            </Message>)
        );
      } else if (errors !== null) {
        where = 'errors';
        pending = Object.keys(utils.objectFilter(errors, error => Object.keys(error).length)).length;
        enabled = pending === 0;
        rows = this.renderErrorRows();
        message = (
          enabled ?
            <Message success icon="check" header={__('No errors')} /> :
            (<Message error>
              <Popup
                basic
                on="click"
                hideOnScroll
                flowing
                trigger={<Button floated="right" basic icon="cog" />}
                content={
                  <div>
                    <p>{__('Apply a prefix to all phone numbers')}</p>
                    <PhoneInput
                      placeholder={__('Prefix')}
                      action={{ primary: true, 'data-action': 'apply-prefix', content: __('Apply'), onClick: this.onApplyPrefix }}
                      country={this.state.countryCode}
                      phone={this.state.phonePrefix}
                      onCountryChange={countryCode => this.setState({ countryCode })}
                      //onPhoneChange={phonePrefix => this.setState({ phonePrefix })}
                      onPhoneBlur={phonePrefix => this.setState({ phonePrefix })}
                    />
                  </div>
                }
              />
              <Message.Header>{__('Number of rows with errors:') + ' ' + pending}</Message.Header>
              <p>{__('There are some errors in your table, fix them to continue')}</p>
            </Message>)
        );
      } else {
        where = 'columns';
        pending = 0;
        enabled = headers.find(header => header.column === 'eid' || header.column === 'fullname') && headers.filter(header => !header || header.column === null || header.column === '' ||
          (header.column === 'address' && this.props.store.currentOrganization.tags.nodes.length && !header.parameters.tags.length && type === 'student')).length === 0;
        rows = this.renderBasicRows();
        message =
          (<div>
            <Header>{__('Import settings')}</Header>
            <Form.Radio
              data-action="keep-old-data"
              label={__('Keep old data: Contacts (e-mail and phone) and existing groups will be kept')}
              name="attach"
              value="true"
              checked={attach}
              onClick={() => this.setState({ attach: true })}
            />
            <Form.Radio
              data-action="substitute-old-data"
              label={__('Substitute old data with new one: Contacts (e-mail and phone) and existing groups will be replaced')}
              name="attach"
              value="false"
              checked={!attach}
              onClick={() => this.setState({ attach: false })}
            />
            <Divider hidden fitted />
            <Header content={__('Choose the columns for each row')} subheader={__('It is required that you import entities with fullname and/or eid')} />
          </div>);
      }

      const columns = errors || duplicates ? this.renderFixedColumns() : this.renderInputColumns();

      view = (
        <div>
          {message}
          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell icon={where === 'columns' ? 'arrow right' : 'arrow down'} />
                {
                  (where === 'errors' || where === 'duplicates') &&
                  <Table.HeaderCell />
                }
                {
                  columns.map((column, i) => {
                    if ((where === 'errors' || where === 'duplicates') && !column) return null;
                    return <Table.HeaderCell key={i}>{column}</Table.HeaderCell>;
                  })
                }
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                array.length ?
                  rows
                  :
                  <Table.Row>
                    <Table.Cell colSpan={6}>
                      <Message
                        icon="inbox"
                        content={__('There are no items to display')}
                      />
                    </Table.Cell>
                  </Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
      );
    }

    return (
      <Modal
        id="EntityImport"
        open
        size="fullscreen"
      >
        <Modal.Header>
          {this.state.title}
        </Modal.Header>
        <Modal.Content style={{ overflow: 'scroll', maxHeight: '480px', minHeight: '460px' }}>
          <Step.Group size="tiny" fluid style={{ display: '-webkit-box' }}>
            <Step
              completed={(array !== null)}
              // active={(file === null && string === '')}
              active={(errors === null && array === null)}
              icon="arrow up"
              title={__('Upload content')}
            />
            <Step
              completed={(errors !== null)}
              active={(array !== null && errors === null)}
              icon="columns"
              title={__('Choose columns')}
            />
            <Step
              completed={(duplicates !== null)}
              active={(errors !== null && duplicates === null)}
              icon="server"
              title={__('Verify errors')}
            />
            <Step
              completed={(entities !== null)}
              active={(duplicates !== null && entities === null)}
              icon="server"
              title={__('Verify duplicates')}
            />
            <Step
              completed={(importFinished)}
              active={(entities !== null && !importFinished)}
              icon="info circle"
              title={__('Confirm and import')}
            />
          </Step.Group>
          <Divider />
          {view}
        </Modal.Content>
        <Modal.Actions>

          {importError || diffError ?
            <Button
              data-action="cancel"
              primary
              onClick={() => this.onClose()}
              content={__('Close')}
            /> :
            confirmed ?
              <Button
                data-action="submit"
                primary
                onClick={() => {
                  this.setState({ loading: true });
                  this.onSubmit();
                  this.setState({ loading: false }); // Disable button loading animation after submit complete
                }}
                content={__('Close')}
              /> :
              ([
                <Button
                  data-action="back"
                  key={0}
                  basic
                  floated="left"
                  labelPosition={this.state.array ? 'left' : false}
                  icon={this.state.array ? 'arrow left' : false}
                  onClick={this.onCancel}
                  content={this.state.import ? __('Stop importing') : (this.state.array ? __('Back') : __('Cancel'))}
                />,
                this.state.array ?
                  <Button
                    data-action="cancel"
                    key={1}
                    basic
                    floated="left"
                    onClick={() => this.props.router.goBack()}
                    content={__('Cancel importing')}
                  />
                  :
                  null,
                <Button
                  data-action="submit"
                  key={2}
                  positive
                  loading={loading}
                  disabled={!enabled || loading}
                  onClick={() => {
                    this.setState({ loading: true });
                    this.onSubmit();
                    this.setState({ loading: false });
                  }}
                  labelPosition="right"
                  icon={entities === null ? 'arrow right' : 'arrow down'}
                  content={entities === null ? __('Next') : __('Import')}
                />
              ])
          }
        </Modal.Actions>
      </Modal>
    );
  }
}
