import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flattenDeep, uniq } from 'lodash';
import moment from 'moment';

import Form from '../../components/ui/Form';
import BetaModal from '../../components/ui/Modal';
import FeaturesModal from '../../components/FeaturesModal';
import PictureInput from '../../components/ui/PictureInput';
import SelectGroup from '../../components/ui/SelectGroup';
import SelectPermissions from '../../components/ui/SelectPermissions';
import RadioButtonList from '../../components/RadioButtonList';

import Button from '../../components/ui/Button';
import AddressForm from '../Address/ui/Form';
import AddressItem from '../Address/ui/Item';

import { __ } from '../../i18n';
import { checkIfItIsClientOrSchool, hasEntityInCurrentOrganization } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  },
  subTitle: {
    margin: '0 0 24px 0'
  },
  subTitleText: {
    fontSize: '1.286rem',
    fontWeight: 700,
  },
  descriptionInput: {
    resize: 'none'
  },
  fieldTitle: {
    margin: '0 0 20px 0'
  },
  fieldTitleText: {
    fontSize: '1.143rem',
    fontWeight: 400,
  },
  photoButton: {
    marginRight: '0.75rem'
  },
  tag: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    border: 'none'
  },
  section: {
    marginBottom: '30px'
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  },
  popup: {
    fontWeight: 300,
    fontSize: '20px',
    opacity: '.6',
    marginLeft: '5px'
  },
};

const scopeSettings = {
  messages: {
    typename: ['MESSAGE'],
    read: {
      requirements: []
    },
    delete: {
      requirements: [
        {
          type: 'messages',
          action: 'read'
        }
      ],
      info: __('Only moments can be deleted for now')
    }
  },
  reports: {
    typename: ['REPORT'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'reports',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'reports',
          action: 'read'
        }
      ]
    }
  },
  entities: {
    typename: ['ENTITY', 'GROUP'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ],
      info: __('Only groups can be deleted')
    }
  },
  dashboard: {
    typename: ['DASHBOARD'],
    read: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ]
    }
  },
  integration: {
    typename: ['INTEGRATION'],
    read: {
      requirements: []
    }
  },
  payments: {
    typename: ['PAYMENT'],
    read: {
      requirements: []
    }
  },
  accounts: {
    typename: ['ACCOUNT'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'accounts',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'accounts',
          action: 'read'
        }
      ]
    }
  },
  organization: {
    typename: ['ORGANIZATION'],
    update: {
      requirements: []
    }
  },
  forms: {
    typename: ['FORM'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'forms',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'forms',
          action: 'read'
        }
      ]
    }
  },
  links: {
    typename: ['LINK'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'links',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'links',
          action: 'read'
        }
      ]
    }
  },
};

const HandleDisablePerson = props =>
(
  <BetaModal
    id="InfoModal"
    onClose={props.onClose}
    actions={[
      <Button
        floated="left"
        transparent
        data-action="cancel"
        round
        text={__('Cancel')}
        onClick={props.onCancel}
      />,
      <Button
        data-action="ok"
        round
        text={__('Confirm')}
        onClick={props.onClick}
      />,
    ]}

    header={props.disabled ? __('Disable person') : __('Enable person')}
    content={
      <div>
        <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
          {props.disabled ? __('Are you sure you want to disable this person?') : __('Are you sure you want to enable this person?')}
        </span>
      </div>
    }
  />
);

@inject('store')
@graphql(gql`mutation createGroup($createGroupMutation: CreateGroupInput!) {
  createGroup(input: $createGroupMutation) {
    group {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['EntityAddQuery', 'EntityEditQuery', 'OrganizationGroupsQuery']
  }
})
@observer
export default class EntityForm extends Form {
  constructor(props) {
    super(props);
    this.disabled = props.values.disabled;
  }

  defaultValues = {
    fullname: '',
    type: 'STUDENT',
    addresses: [],
    eid: '',
    seeAll: false,
    invisible: true,
    policy: [],
    groups: [],
    defaultFeatures: ['MESSAGE'],
    scope: {
      messages: {
        read: false,
        delete: false
      },
      reports: {
        read: false,
        createUpdate: false,
        delete: false
      },
      entities: {
        read: false,
        createUpdate: false,
        delete: false,
        contentApprover: false,
      },
      dashboard: {
        read: false
      },
      integration: {
        read: false
      },
      payments: {
        read: false
      },
      accounts: {
        read: false,
        createUpdate: false,
        delete: false
      },
      organization: {
        update: false
      },
      forms: {
        read: false,
        createUpdate: false,
        delete: false
      },
      links: {
        read: false,
        createUpdate: false,
        delete: false
      },
      policy: []
    }
  }

  rules = {
    fullname: ['required', values => this.validateContent('fullname', values)]
  }

  sendGAEvent = (name, category) => {
    sendGoogleAnalyticsEvent({
      name,
      category,
    }, {store: this.props.store})
  }

  onAddressSubmit = (address) => {
    const newAddress = address.address.toLowerCase();
    if (this.state.values.addresses.map(ad => ad.address)
      .find((ad) => {
        const oldAddress = ad.toLowerCase();

        return oldAddress === newAddress || oldAddress === newAddress.replace(/[^0-9]+/g, '');
      })) {
      this.props.store.snackbar = {
        active: true,
        success: false,
        message: __('You can\'t add an existing address, please choose another one')
      };
    } else {
      this.props.store.appends.pop();
      this.pushValue('addresses', address);
    }
  }

  getTypeOptions = () => {
    const isMaster = this.props.store.currentUser && this.props.store.currentUser.isMaster;
    const isAdmin = this.props.store.currentEntity && this.props.store.currentEntity.type && this.props.store.currentEntity.type === 'ADMIN';

    return ([
      {
        value: 'STUDENT', icon: 'user graduate', text: checkIfItIsClientOrSchool(this.props.organization && this.props.organization.type) === 1 ? __('Student') : __('Client')
      }, {
        value: 'STAFF', icon: 'clipboard user', text: __('Staff')
      }, {
        value: 'ADMIN', icon: 'user cog', text: __('Admin')
      }
    ].filter(type => (isMaster || isAdmin || type.value !== 'ADMIN')));
  };

  openAddressForm = (type = 'EMAIL') => {
    const { router } = this.props;
    const title = type === 'EMAIL' ? __('Add email') : __('Add phone');
    const addUrl = router.location.pathname.endsWith('/add')
    const editUrl = router.location.pathname.endsWith('/edit')

    if (addUrl) {
      if (this.state.values.type === 'STUDENT') {
        if (type === 'EMAIL') {
          this.sendGAEvent('add_person', 'click to add an email address to a student entity')
        } else if (type === 'PHONE') {
          this.sendGAEvent('add_person', 'click to add a phone number to a student entity')
        }
      } else {
        if (type === 'EMAIL') {
          this.sendGAEvent('add_person', 'click to add an email address to a staff/admin entity')
        } else if (type === 'PHONE') {
          this.sendGAEvent('add_person', 'click to add a phone number to a staff/admin entity')
        }
      }
    } 
    
    if(editUrl) {
      if (this.state.values.type === 'STUDENT') {
        if (type === 'EMAIL') {
          this.sendGAEvent('edit_person', 'click to edit email address of a student entity')
        } else if (type === 'PHONE') {
          this.sendGAEvent('edit_person', 'click to edit phone number of a student entity')
        }
      } else {
        if (type === 'EMAIL') {
          this.sendGAEvent('edit_person', 'click to edit email address of a staff/admin entity')
        } else if (type === 'PHONE') {
          this.sendGAEvent('edit_person', 'click to edit phone number of a staff/admin entity')
        }
      }
    }

    this.props.store.appends.push(
      <BetaModal
        id="AddressAdd"
        data-params={type}
        onClose={() => this.props.store.appends.pop()}
        closeOnBack
        actions={[
          <Button
            data-action="cancel"
            floated="left"
            round
            transparent
            style={{ marginBottom: '0px' }}
            text={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
        header={title}
        content={
          <AddressForm
            modal
            values={{ type }}
            onSubmit={this.onAddressSubmit}
            submitButton={__('Add')}
            submitButtonIcon="check"
            tags={this.props.organization && this.props.organization.tags}
            entity_type={this.state.values.type}
          />
        }
      />
    );
  }

  renderAddresses = () => {
    const notAcceptedAddresses = this.state.values.addresses.filter(address => address.user === null);

    if (this.props.add || this.state.values.addresses.filter(address => address.user === undefined)) {
      const newAddresses = this.state.values.addresses.filter(address => address.user === undefined || address.user === null);

      if (newAddresses.length === 0) {
        return (
          <div style={styles.block}>
            <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {__('No invitation')}
            </span>
          </div>
        );
      }

      return (
        <div style={{}}>
          {newAddresses.map((address, key) => (
            <AddressItem
              key={`new-address-${key}`}
              address={address}
              onDelete={() => this.popValue('addresses', this.state.values.addresses.findIndex(a => a.address === address.address))}
            />
          ))}
        </div>
      );
    }

    if (notAcceptedAddresses.length === 0) {
      return (
        <div style={styles.block}>
          <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
            {__('No invitation')}
          </span>
        </div>
      );
    }

    return (
      <List celled>
        {notAcceptedAddresses.map((address, key) => (
          <AddressItem
            key={`not-accepted-address-${key}`}
            address={address}
            onDelete={() => this.popValue('addresses', this.state.values.addresses.findIndex(a => a.address === address.address))}
          />
        ))}
      </List>
    );
  }

  beforeSubmit = (values) => {
    if (values.groups.length > 0) {
      values.groups = values.groups.map(group => ({ id: group.id, name: group.name, type: group.type }));
    }

    values.addresses = values.addresses.map(({ user, ...address }) => address);
    const isApprover = values.scope && values.scope.entities && values.scope.entities.contentApprover

    const scopeValidOptions = this.props.scope ? flattenDeep(Object.keys(scopeSettings).map((type) => {
      const actionsWithPermissionGranted = ['read', 'createUpdate', 'update', 'delete'];

      return scopeSettings[type].typename.map(typename => actionsWithPermissionGranted.map((action) => {
        switch (action) {
          case 'read':
            return 'READ_' + typename;
          case 'createUpdate':
            return 'CREATE_UPDATE_' + typename;
          case 'update':
            return 'UPDATE_' + typename;
          case 'delete':
            return 'DELETE_' + typename;
          default:
            return null;
        }
      }));
    })) : [];
    values.scope = [
      ...(this.props.scope || []).filter(permission => !scopeValidOptions.includes(permission) && permission !== 'CONTENT_APPROVER'),
      ...(Object.keys(scopeSettings).map((type) => {
        const actionsWithPermissionGranted = ['read', 'createUpdate', 'update', 'delete'].filter(action => values.scope[type][action]);

        return scopeSettings[type].typename.map(typename => actionsWithPermissionGranted.map((action) => {
          switch (action) {
            case 'read':
              return 'READ_' + typename;
            case 'createUpdate':
              return 'CREATE_UPDATE_' + typename;
            case 'update':
              return 'UPDATE_' + typename;
            case 'delete':
              return 'DELETE_' + typename;
            default:
              return null;
          }
        }));
      }))
    ];
    values.scope = flattenDeep(values.scope).filter(scope => !['DELETE_ENTITY'].includes(scope));
    if (isApprover) values.scope.push('CONTENT_APPROVER')
    values.scope = uniq(values.scope)
    return values;
  }

  validateContent = (fullname, values) => {
    if (fullname === 'fullname' && values.trim().length === 0) {
      throw new Error(__('Fullname cannot be empty'));
    }
  }

  renderAdministrativePermissions = (store, values, organization, scope, policy = null) => {
    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM';

    if (values.type !== 'STAFF') return null;
    return (
      <div style={styles.section}>
        <div style={styles.subTitle}>
          <span style={styles.subTitleText}>{__('Manage Permissions')}</span>
        </div>
        <SelectPermissions
          scope={scope}
          onSubmit={(scope => this.setValue('scope', scope))}
          openPaywall={
            !(organization.plan !== 'FREE' || (organization.premiumPeriod && moment().isBefore(organization.premiumPeriod))) &&
            this.openPaywall
          }
        />
        {hasMessageApproval && <SelectPermissions
          entity={values}
          messageApproval
          scope={scope}
          policy={policy}
          onSubmit={((scope, policy) => {
            this.setValue('scope', scope)
            this.setValue('policy', policy)
          })}
          openPaywall={
            !(organization.plan !== 'FREE' || (organization.premiumPeriod && moment().isBefore(organization.premiumPeriod))) &&
            this.openPaywall
          }
          radioList={[
            { value: 'notApprove', label: __('Send messages without going through approval') },
            { value: 'approveAndReprove', label: __('Approve or not approve messages sent by others') },
            { value: 'submitToApprove', label: __('Submit messages for approval before sending') },
          ]}
        />}
      </div>);
  }

  openPaywall = () => {
    this.props.store.appends.push(<FeaturesModal feature={'ADMINISTRATION'} />);
  }

  renderStatus = (values, edit, disabled) => {
    if (disabled) return null;

    const status = edit ? (<div style={styles.section}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{__('Status')}</span>
      </div>
      <Form.Switch
        name="status"
        type="status"
        color={'#084FFF'}
        options={[{ value: false, text: __('Enabled') }, { value: true, text: __('Disabled') }]}
        checked={values.disabled}
        onChange={(e, d) => this.setState({ values: { ...values, disabled: d.value } })}
      />
    </div>)
      : null;

    return status;
  }

  render() {
    const { values, errors } = this.state;
    const { store, organization, cancelButton, submitButton, submitButtonIcon, loading, edit } = this.props;
    const { scope } = values;
    const { policy } = values;
    const hasMessageApproval = store.currentOrganization
      && store.currentOrganization.unleashStatus
      && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM';
    const errorsModal = {
      message: __('Please, add a name to the person'),
    };
    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={() => (this.props.onClose ? this.props.onClose() : this.props.router.goBack())}
    />];
    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton.text}
      loading={loading}
      onClick={(e, event) => (values.disabled !== this.disabled ?
        this.props.store.appends.push(
          <HandleDisablePerson
            disabled={values.disabled}
            onClose={() => this.props.store.appends.pop()}
            onClick={() => {
              this.handleSubmit(e, event, { errorsModal });
              sendGoogleAnalyticsEvent({
                name: 'Entity Status Menu',
                category: `Status: ${values.disabled ? 'Disabled' : 'Enabled'}`,
                label: `OrganizationID: ${organization.id}`,
              }, { store });
              this.props.store.appends.pop();
            }}
            onCancel={() => this.props.store.appends.pop()}
          />
        )
        : this.handleSubmit(e, event, { errorsModal }))
      }
    />];

    const messagePermission = (
      <span>
        {hasMessageApproval ? (
          __('Grants permission to send and approve messages to any groups, students, or staff of the institution.')
        ) : (
          __('Grants permission to send messages to any groups, students, or staff of the institution.')
        )}
      </span>
    );

    return (
      <Form
        id="EntityForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event, { errorsModal })}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={this.isMobile() ? {
          title: '',
          onClose: () => (this.props.onClose ? this.props.onClose() : this.props.router.goBack()),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            loading={loading}
            text={submitButton.text}
            onClick={(e, event) => this.handleSubmit(e, event, { errorsModal })}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        } : null}
      >
        <div style={styles.section}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Profile')}</span>
          </div>
          <Form.Switch
            name="type"
            type="entity"
            cssTags={this.isMobile() ? 'autowidth small' : ''}
            checked={values.type}
            options={this.getTypeOptions()}
            onChange={(e, { name, value }) => {
              if (value === values.type) return;
              let cb = null;
              if (value === 'STUDENT') {
                cb = () => this.setValue('seeAll', false);
              }
              this.setValue(name, value, cb);
            }}
          />
        </div>
        {
          this.renderStatus(values, edit, !this.disabled)
        }
        <div style={styles.subTitle}>
          <span style={styles.subTitleText}>{__('Details')}</span>
        </div>
        <div style={styles.section}>
          <div style={{ display: 'flex' }}>
            <Form.Input
              style={{ flex: 11, marginRight: '8px' }}
              label={__('Full name')}
              placeholder={__('Enter the person\'s name...')}
              name="fullname"
              value={values.fullname}
              onChange={e =>
                this.onTextInputChange(e, { name: 'fullname', value: e.target.value, maxDigits: 255 })
              }
            />
            <Form.Input
              style={{ flex: 5 }}
              label={__('External ID')}
              placeholder={__('Optional')}
              name="eid"
              value={values.eid}
              onChange={e =>
                this.onTextInputChange(e, { name: 'eid', value: e.target.value, maxDigits: 255 })
              }
            />
          </div>
          {errors && errors.fullname && errors.fullname !== 'Error' &&
            <div className="error-warning-red" >
              <Icon data-value="error" name="exclamation circle" />
              {errors.fullname}
            </div>
          }
          <Form.TextArea
            style={{ ...styles.descriptionInput }}
            label={__('Description')}
            cssTags={this.isMobile() ? 'big-height' : ''}
            placeholder={__('Write the position or other informations...')}
            name="description"
            value={values.description}
            onChange={e =>
              this.onTextInputChange(e, { name: 'description', value: e.target.value, maxDigits: 255 })
            }
          />
        </div>
        {(((values.picture && values.picture.id && values.picture.uri) || values.picture instanceof Blob) || hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster || (store.currentEntity && store.currentEntity.id === values.id)) &&
          <div style={styles.section}>
            <div style={{ ...styles.subTitle }}>
              <span style={{ ...styles.subTitleText }}>{__('Picture')}</span>
            </div>
            <PictureInput
              entityId={values.id}
              hideEdit={!(hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster || (store.currentEntity && store.currentEntity.id === values.id))}
              picture={((values.picture && values.picture.id && values.picture.uri) || values.picture instanceof Blob) ? values.picture : null}
              fullname={values.fullname}
              onSubmit={picture => this.setValue('picture', picture)}
              onDelete={() => this.setValue('picture', null)}
            />
          </div>
        }

        <div style={styles.section}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Groups')}</span>
          </div>
          <SelectGroup
            organizationId={organization.id}
            selectedItems={values.groups}
            visibility={null}
            onChange={visibility => this.setValue('visibility', visibility)}
            onSubmit={(selectedGroups) => {
              this.setValue('groups', selectedGroups);
            }}
            onDelete={(newSelectedGroups) => {
              this.setValue('groups', newSelectedGroups);
            }}
          />
        </div>
        {
          values.type === 'STAFF' &&
          <div style={{ ...styles.section, display: 'flex', alignItems: 'baseline' }}>
            <Form.Checkbox
              label={__('Allow communication with all users')}
              name="seeAll"
              radio
              radioChecked
              bold={false}
              checked={values.seeAll}
              onClick={() => this.onInputChange(null, { name: 'seeAll', checked: !values.seeAll })}
            />
            <Popup
              trigger={<Icon style={styles.popup} name="info circle" color="grey" />}
              content={messagePermission}
              hideOnScroll
              basic
            />
          </div>
        }
        {
          values.type === 'ADMIN' &&
          <div style={{ ...styles.section, display: 'flex', alignItems: 'baseline' }}>
            <Form.Checkbox
              label={__('An admin has access to all users')}
              name="seeAll"
              radio
              disabled
              radioChecked
              bold={false}
              checked
              onClick={() => this.onInputChange(null, { name: 'seeAll', checked: !values.seeAll })}
            />
            <Popup
              trigger={<Icon style={styles.popup} name="info circle" color="grey" />}
              content={
                <span>
                  {messagePermission}
                  <p>{__('Always selected for admins.')}</p>
                </span>
              }
              hideOnScroll
              basic
            />
          </div>
        }
        <div style={styles.section}>
          <div style={{ ...styles.subTitle, display: 'flex', alignItems: 'center' }}>
            <span style={styles.subTitleText}>{__('Invites')}</span>
            <Popup
              trigger={<Icon style={{ ...styles.popup, marginBottom: '3px' }} name="info circle" color="grey" />}
              content={<span>{__('List of invitations not yet in this profile.')}</span>}
              hideOnScroll
              basic
            />
          </div>
          <div>
            {this.renderAddresses()}
          </div>
          <div style={{ marginTop: '10px' }}>
            {errors && errors.address && <p style={{ color: 'red', padding: '16px 0px' }}>{errors.address}</p>}
            <Button
              round
              transparent
              icon={{ name: 'at' }}
              text={this.isMobile() ? __('Email') : __('Add email')}
              onClick={() => this.openAddressForm('EMAIL')}
              style={{ marginRight: '16px' }}
            />
            <Button
              round
              transparent
              icon={{ name: 'mobile' }}
              text={this.isMobile() ? __('Phone') : __('Add phone')}
              onClick={() => this.openAddressForm('PHONE')}
            />
          </div>
        </div>
        {
          this.renderAdministrativePermissions(store, values, organization, scope, policy)
        }
        {
          this.renderStatus(values, edit, this.disabled)
        }
      </Form>
    );
  }
}
