import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import ImageEditor from '../../components/ui/ImageEditor';
import * as utils from '../../utils';
import { __ } from '../../i18n';

const createPicture = entityId => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

@inject('store', 'client', 'api')
@graphql(gql`
mutation deletePicture($deletePictureMutation: DeletePictureInput!) {
  deletePicture(input: $deletePictureMutation) {
    clientMutationId
  }
}`, {
  name: 'deletePicture'
})
@observer
export default class EntityPicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  onSubmit = async (picture) => {
    const { store, api, router } = this.props;
    const entityId = store.entity.id;
    if (picture) {
      this.setState({ loading: true });
      await api.upload('picture', {
        file: picture,
        query: createPicture(entityId)
      })
        .then(async () => {
          await this.props.client.resetStore();
          if (store.history.length > 1) {
            router.goBack();
          } else {
            const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
              (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
            router.push(link);
          }
          store.snackbar = { active: true, message: __('Image uploaded'), success: true };
          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log(err);
          store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
          this.setState({ loading: false });
        });
    }
  }

  onCancel = () => {
    const { store, router } = this.props;
    if (store.history.length > 1) {
      router.goBack();
    } else {
      const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
        (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
      router.push(link);
    }
    this.setState({ loading: false });
  }

  onDelete = async () => {
    const { store, router } = this.props;
    this.setState({ loading: true });
    this.props.deletePicture({
      variables: { deletePictureMutation: { entityId: this.props.params.entity_id } }
    }).then(async () => {
      await this.props.client.resetStore();
      if (store.history.length > 1) {
        router.goBack();
      } else {
        const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
          (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
        router.push(link);
      }
      store.snackbar = { active: true, message: __('Image deleted'), success: true };
      this.setState({ loading: false });
    }).catch((err) => {
      console.log(err);
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      this.setState({ loading: false });
    });
  }

  render() {
    const { store } = this.props;
    const type = store.entity.picture && store.entity.picture.id ? 'edit' : 'add';
    return (
      <ImageEditor
        id="EntityPicture"
        header={type === 'add' ? __('Add picture') : __('Edit picture')}
        onSubmit={this.onSubmit}
        onDelete={this.onDelete}
        onCancel={this.onCancel}
        canEdit
        loading={this.state.loading}
        type={type}
        picture={store.entity.picture}
        circular
      />
    );
  }
}
