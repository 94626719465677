import React from 'react';
import { inject, observer } from 'mobx-react';
import { List, Button, Message, Divider, Loader, Header, Segment, Popup, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { cloneDeep, includes, reduce } from 'lodash';

import Modal from '../../components/Modal';
import ListView from '../../components/ListView';
import Page from '../../components/Page';

import UserItem from '../User/Item';
import AddressForm from '../Address/Form';
import AddressItem from '../Address/Item';
import UserEntityTagAdd from '../UserEntityTag/Add';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import * as analytics from '../../lib/analytics';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query EntityAccountsQuery ($id: ID!, $limit: Int, $offset: Int) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        type
        organization {
          id: dbId
          live
          tags {
            nodes {
              name
              checked
              id: dbId
            }
          }
        }
        users {
          nodes {
            ... UserItem
          }
        }
        addresses(limit: $limit, offset: $offset) {
          nodes {
            ... AddressItem
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
  ${UserItem.fragments.user}
  ${AddressItem.fragments.address}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
@graphql(gql`mutation deleteUserEntityTag($deleteUserEntityTagMutation: DeleteUserEntityTagInput!) {
  deleteUserEntityTag(input: $deleteUserEntityTagMutation) {
    clientMutationId
  }
}`, {
  name: 'deleteUserEntityTag',
  options: {
    refetchQueries: ['EntityAccountsQuery']
  }
})
@graphql(gql`mutation createAddress($createAddressMutation: CreateAddressInput!) {
  createAddress(input: $createAddressMutation) {
    clientMutationId
  }
}`, {
  name: 'createAddress',
  options: {
    refetchQueries: ['EntityAccountsQuery', 'UserInvitationsQuery', 'EntityEditQuery']
  }
})
@graphql(gql`mutation updateAddress($updateAddressMutation: UpdateAddressInput!) {
  updateAddress(input: $updateAddressMutation) {
    clientMutationId
  }
}`, {
  name: 'updateAddress'
})
@observer
export default class EntityAccounts extends Page {
  postAddress = (address) => {
    this.props.store.appends.pop();

    const { params, createAddress, data, store } = this.props;

    const newAddress = address.address.toLowerCase();

    if (data.node.addresses.nodes.find((a) => {
      const oldAddress = a.address.toLowerCase();

      return oldAddress === newAddress || oldAddress === newAddress.replace(/[^0-9]+/g, '');
    })) {
      store.snackbar = { active: true, message: __('Entity have this address already'), success: false };
      return;
    }

    createAddress({
      variables: {
        createAddressMutation: {
          ...address,
          entityId: params.entity_id,
          address: newAddress,
        }
      }
    }).then(() => {
      this.props.data.refetch();
      store.snackbar = { active: true, message: __('New address added successfully'), success: true };
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }

  inviteAddress = (address, index) => {
    this.props.updateAddress({
      variables: {
        updateAddressMutation: {
          id: address.id,
          invite: true
        }
      }
    }).then(() => {
      this.props.data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        const node = nextResult.node.addresses.nodes[index];

        if (!node.status) {
          node.status = 1;
        } else {
          node.status += 1;
        }

        return nextResult;
      });

      this.props.store.snackbar = { active: true, message: __('Address invited'), success: true };
    });
  }

  deleteAddress = (address) => {
    const urlParam = address.code ? `/${address.code}` : '';
    this.props.router.push(`/entities/${this.props.params.entity_id}/accounts/addresses${urlParam}/delete/${address.id}`);
  }

  deleteUserEntity = (user) => {
    this.props.router.push(`/entities/${this.props.params.entity_id}/accounts/users/${user.id}/delete`);
  }

  openAddressForm = (type = 'EMAIL') => {
    const { store } = this.props;
    const isEmail = type === 'EMAIL';
    const title = isEmail ? __('Add email') : __('Add phone');

    analytics.sendGoogleAnalyticsEvent(
      {
        name: 'Account',
        category: isEmail ? 'add_email' : 'add_phone',
        label: `OrganizationId: ${store?.currentOrganization?.id} | EntityId: ${store?.currentEntity?.id}`,
      },
      { store },
    );

    this.props.store.appends.push(
      <Modal
        onClose={() => this.props.store.appends.pop()}
        actions={[
          <Button
            data-action="cancel"
            floated="left"
            basic
            content={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
        header={title}
        content={
          <AddressForm
            modal
            values={{ type }}
            onSubmit={this.postAddress}
            submitButton={__('Add')}
            tags={this.props.data.node.organization && this.props.data.node.organization.tags.nodes}
            entity_type={this.props.data.node.type}
          />
        }
      />
    );
  }

  openTagForm = (user) => {
    const { store, params } = this.props;

    store.appends.push(<UserEntityTagAdd
      user={user}
      entity_id={params.entity_id}
    />);
  }

  deleteUserEntityTag = (user_id, tag_id) => {
    const { params, store, deleteUserEntityTag } = this.props;

    deleteUserEntityTag({
      variables: {
        deleteUserEntityTagMutation: {
          userId: user_id,
          entityId: params.entity_id,
          tagId: tag_id
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('Tag deleted successfully'), success: true };
    }).catch(() => {
      store.snackbar = { active: true, message: __('It was not possible to delete this tag'), success: false };
    });
  }

  renderUserRow = (user, key) => (
    <UserItem
      key={key}
      user={filter(UserItem.fragments.user, user)}
      onDelete={this.deleteUserEntity}
      onAddTag={this.props.data.node.organization && this.openTagForm}
      onDeleteTag={this.deleteUserEntityTag}
    />
  )

  renderUsers = () => {
    const { data } = this.props;

    const users = data.node.users;
    const { nodes } = users;

    if (nodes && !nodes.length) return <Message content={__('No users registered')} />;

    return (
      <List horizontal={!this.isMobile()}>
        {nodes.map((user, key) => this.renderUserRow(user, key))}
      </List>
    );
  }

  renderAddressRow = (address, key) => {
    const { currentUser } = this.props.store;
    const { organization, type } = this.props.data.node;

    if (address.user) {
      return null;
    }

    return (<AddressItem
      key={key}
      address={filter(AddressItem.fragments.address, address)}
      onInvite={(organization && organization.live !== null) || type !== 'STUDENT' || currentUser.isMaster ?
        () => this.inviteAddress(address, key) : null
      }
      onDelete={() => this.deleteAddress(address, key)}
    />);
  }

  renderAddresses = () => {
    const { data } = this.props;

    const { addresses, users } = data.node;
    const { nodes, pageInfo } = addresses;

    const usersAddress = reduce(users.nodes, (acc, user) => {
      if (user.email) acc.push(user.email);
      if (user.phone) acc.push(user.phone);
      return acc;
    }, []);

    const newNodes = reduce(nodes, (acc, node) => {
      if (!includes(usersAddress, node.address)) acc.push(node);
      return acc;
    }, []);

    if (nodes && !nodes.length) return <Message id="noContent" content={__('No invitations sent')} />;

    return (
      newNodes && newNodes.length > 0 && <ListView
        renderRow={this.renderAddressRow}
        source={newNodes}
        pagination={{
          graphql: true,
          hasNextPage: pageInfo && pageInfo.hasNextPage,
          loading: data.loading
        }}
        onLoadMore={() => this.loadMore('addresses')}
      />
    );
  }

  render() {
    const { data } = this.props;

    if (!data.node || data.loading) return <Loader active inline="centered" />;

    return (
      <div id="EntityAccounts">
        <Header>{__('Accounts')}</Header>
        <Segment>
          <Divider horizontal>{__('Users')}</Divider>
          {this.renderUsers()}
          <Divider horizontal>
            {__('Invites')}
            <Popup
              trigger={<Icon style={{ opacity: '.6', marginLeft: '5px', marginBottom: '3px' }} name="info circle" color="grey" />}
              content={<span>{__('List of invitations not yet in this profile.')}</span>}
              hideOnScroll
              basic
            />
          </Divider>
          {data.node.organization &&
            <div style={{ marginTop: this.isMobile() ? '20px' : '0px' }}>
              <Button
                data-action="open-address-form"
                data-params="email"
                icon="envelope"
                content={__('Add email')}
                style={{ marginBottom: this.isMobile() ? '15px' : '0px' }}
                onClick={() => this.openAddressForm('EMAIL')}
              />
              <Button
                data-action="open-address-form"
                data-params="phone"
                icon="phone"
                content={__('Add phone')}
                onClick={() => this.openAddressForm('PHONE')}
              />
            </div>}
          {this.renderAddresses()}
          {this.props.children}
        </Segment>
      </div>
    );
  }
}
