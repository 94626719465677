import cookie from 'react-cookie';
import gql from 'graphql-tag';
import qs from 'qs';
import { browserHistory } from 'react-router';
import { __ } from '../../../i18n';

import * as utils from '../../../utils';
import { sendGoogleAnalyticsEvent } from '../../../lib/analytics';

/**
 * @param {{
 *  store: {
 *    entity: {
 *      type: utils.EntityType,
 *      organization: {
 *        plan: utils.OrganizationPlan,
 *      }
 *    }
 *  }
 * }} props
 * @returns {boolean}
 */
export const canSeeFeatureAnnouncement = (props) => {
  const { store } = props;
  const { entity } = store;
  const { organization } = entity;
  if (!organization || !entity) return false;
  const hasSeenApprovalMessageBanner = organization.plan === 'PREMIUM'
    ? !!cookie.load('hasSeenApprovalMessageAdminBanner')
    : (organization.plan === 'STANDARD' || organization.plan === 'STANDARD_NEW')
  && !!cookie.load('hasSeenApprovalMessageAdminStandardBanner');

  const validPlan = organization.plan === 'PREMIUM'
  || organization.plan === 'STANDARD'
  || organization.plan === 'STANDARD_NEW';
  return entity && entity.type === 'ADMIN'
  && !hasSeenApprovalMessageBanner
  && validPlan;
};

/**
 * @param {string} name
 * @param {{
 *  store: {
 *    app: {
 *      host: string,
 *    }
 *  }
 * }} props
 */
export const setCookie = (name, props) => {
  cookie.save(
    `hasSeen${name}Banner`,
    'yes',
    {
      path: '/',
      domain: props.store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
      maxAge: 60 * 60 * 24 * 30
    }
  );
};

/**
 * @param {{
 *  link: string,
 *  id: string,
 * }} newFeature
 * @param {{
 *  organization: {
 *    id: number | string,
 *    fullname: string,
 *    plan: string
 *  }
 * }} entity
 * @param {{
 *  id: string | number,
 *  fullname: string,
 * }} currentUser
 * @param {*} props
 */
export const handleFeatureAnnouncementAction = (newFeature, entity, currentUser, props) => {
  const { organization } = entity;
  const eventAction = organization.plan === 'PREMIUM'
    ? 'Open action button with admin'
    : (organization.plan === 'STANDARD' || organization.plan === 'STANDARD_NEW')
    && 'Open action button with admin on standard modal';
  window.open(newFeature.link, '_blank');
  setCookie(newFeature.id, props);
  sendGoogleAnalyticsEvent({
    name: 'ApprovalMessageAnnouncement',
    category: `${eventAction}`,
    label: `OrganizationID: ${
      entity.organization.id
    } OrganizationFullname: ${
      entity.organization.fullname
    } userId: ${
      currentUser.id
    } userFullname: ${
      currentUser.fullname
    }`
  }, { store: props.store });
};

/**
 * @param {{
 *  apolloClient: import('apollo-client').ApolloClient<import('apollo-cache-inmemory').NormalizedCacheObject>,
 *  organizationId: string | number,
 *  search: string,
 *  limit: number,
 *  entityIds: (string | number)[]
 * }} param0
 * @returns {Promise<any>}
 */
export const fetchStaffList = ({
  apolloClient, organizationId, search, limit, entityIds
}) => {
  const staffSearchQuery = gql`
    query StaffSearchQuery(
      $organizationId: ID!,
      $search: String,
      $limit: Int!,
      $entityIds: [ID],
    ) {
    node (id: $organizationId) {
      ... on Organization {
        id: dbId
        entities(
          search: $search,
          limit: $limit,
          entityIds: $entityIds,
          type: [STAFF],
        ) {
          nodes {
            id: dbId
            fullname
            picture {
              uri
              id: dbId
              key
            }
          }
        }
      }
    }
  }`;

  return apolloClient.query({
    query: staffSearchQuery,
    variables: {
      organizationId,
      search,
      limit,
      entityIds,
    },
  });
};

/**
 * @param {{
 *  query: {
 *    recipientIds?: string,
 *  },
 *  pathname: string,
 * }} location
 * @param {string[]} recipientIds
 */
export const updateLocationQueryRecipientIds = (location, recipientIds) => {
  const parameters = location.query;

  parameters.recipientIds = recipientIds.length ? JSON.stringify(recipientIds) : undefined;

  const params = qs.stringify(parameters);
  const { pathname } = location;

  if (params.length > 0) {
    browserHistory.push(pathname + '?' + params);
  } else {
    browserHistory.push(pathname);
  }
};

/**
* @param {{
*   sendBannerSlackMQL: Function,
*   store: import('../../../store').Store,
* }} props
* @param {{
*   key: string,
*   version: string,
*   snackMessage: string
* }} banner
* @param {Function} saveBannerView
* @returns {Promise<void>}
*/
export const onClickBetaModalActionButton = async (props, banner, saveBannerView) => {
  const { store } = props;
  const { currentUser, currentOrganization } = store;
  const { id: organizationId } = currentOrganization || {};
  try {
    await props.sendBannerSlackMQL({
      variables: {
        bannerKey: banner.key,
        organizationId: store && store.entity && store.entity.organization && store.entity.organization.id
      }
    });
    sendGoogleAnalyticsEvent({
      name: 'RightBanner',
      category: `confirm-modal-${banner.key}-${props.store.app.env}`,
      label: `OrganizationId: ${
        organizationId
      }; userId: ${
        store.currentUser && store.currentUser.id
      }; entityId: ${
        store.currentEntity && store.currentEntity.id
      };`,
    }, { store });
    saveBannerView(banner.version, currentUser.id);

    utils.clearSnackbar(store);
    store.snackbar = {
      active: true, message: __(banner.snackMessage), success: true, dismissAfter: 5000
    };
  } catch (e) {
    console.log(e);
  }
};
