import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Dropdown, Label } from 'semantic-ui-react';

import Controller from '../../../components/Controller';
import MultipleAutosuggestForm from '../../../components/MultipleAutosuggestForm';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

const SUGGESTION_SIZE = 20;

/**
 * @typedef {{
 *  text: string,
 *  value: string,
 *  image?: { avatar: boolean, src: string },
 *  icon: boolean | string,
 *  'data-id': number,
 * }} EntityItem
 */

/**
 * @typedef {{
 *  placeholder: string,
 *  style: { image?: boolean, icon?: string, color?: import('semantic-ui-react').SemanticCOLORS, },
 *  modalCallbacks: {
 *   onOpen: (component: import('react').ReactElement) => void,
 *   onClose: () => void,
 *  },
 *  apiExtract: { name: keyof EntityItem },
 *  resultExtract: { edgeName: string, nodeName?: string, },
 *  fetchInitialData: (params: { ids: string[] }) => Promise<any>,
 *  fetchData: (params: { search: string, limit: number }) => Promise<any>,
 *  handleSelectedItems: (array: EntityItem['value'][]) => void,
 *  className?: string,
 *  icon?: string,
 *  loading?: boolean,
 *  selectedItemTextLength?: number,
 *  initiallySelectedValues?: (string | number)[],
 *  cssStyle?: import('react').CSSProperties,
 *  onFocus?: () => void,
 * }} Props
 *
 * @typedef {{
 *  inputTextValue: string,
 *  suggestedItems: EntityItem[],
 *  selectedItems: EntityItem[],
 *  tempSelectedItems: EntityItem['value'][],
 *  hasSelectedItemsChanged: boolean,
 *  isLoading: boolean,
 *  isOpen: boolean,
 *  buttonLoading?: boolean,
 * }} State
 * @extends {React.Component<Props>}
 */
export default class MultipleAutosuggest extends React.Component {
  /** @param {Props} props */
  constructor(props) {
    super(props);
    /** @type {State} */
    this.state = {
      inputTextValue: '',
      suggestedItems: [],
      selectedItems: [],
      hasSelectedItemsChanged: false,
      tempSelectedItems: [],
      isLoading: false,
      isOpen: false
    };
    this.lastRequestId = null;
  }

  componentDidMount() {
    const {
      initiallySelectedValues, style, fetchInitialData, resultExtract
    } = this.props;

    if (initiallySelectedValues && initiallySelectedValues.length) {
      this.setState({ isLoading: true }, () => {
        const ids = initiallySelectedValues.map(val => `${val}`);

        fetchInitialData({ ids }).then(({ data }) => {
          /** @type {EntityItem[]} */
          const results = [];
          const { edgeName, nodeName = 'node' } = resultExtract;

          data[nodeName][edgeName].nodes.forEach(/** @param {any} node */({ id, fullname, picture }) => {
            results.push({
              text: fullname,
              value: `${id}`,
              image: style.image ? {
                avatar: true,
                src: (picture && picture.uri)
                  || utils.asset(`/avatars/${utils.normalize(fullname[0].toLowerCase())}.svg`, '')
              } : undefined,
              icon: (!style.image && style.icon) || false,
              'data-id': id
            });
          });

          this.setState({
            isLoading: false, suggestedItems: results, selectedItems: results
          });
        });
      });
    }
  }

  // The following code was adapted from the original component. But since we don't need it for
  // now and have removed the prop requestedArguments, this code was commented. If we see that it
  // really is not needed in the process of improving this and the MessageFilter components, we
  // should delete it

  // /** @param {Props} prevProps */
  // componentDidUpdate(prevProps) {
  //   const { requestArguments } = this.props;
  //   const { requestArguments: oldRequestArguments } = prevProps;

  //   if (requestArguments.id !== oldRequestArguments.id) {
  //     this.setState({
  //       inputTextValue: '',
  //       suggestedItems: [],
  //       selectedItems: [],
  //       isLoading: false,
  //       isOpen: false
  //     });
  //     this.lastRequestId = null;
  //   }
  // }

  /** @param {EntityItem['value'][]} newValues */
  onSuggestionChange = (newValues) => {
    const { selectedItems, suggestedItems } = this.state;
    let newSelectedItems = cloneDeep(selectedItems);

    if (newValues.length > selectedItems.length) {
      const newValue = newValues[newValues.length - 1];
      const newItem = suggestedItems.filter(item => item.value === newValue)[0];

      newSelectedItems.push(newItem);
    } else {
      newSelectedItems = selectedItems.filter(({ value }) => newValues.includes(value));
    }

    this.setState({
      selectedItems: newSelectedItems,
      hasSelectedItemsChanged: false,
      tempSelectedItems: [],
    }, () => {
      this.onBlur();

      // @ts-ignore
      this.dropdown.clearSearchQuery();
    });
  }

  /**
   * @param {string} text
   */
  onTextChange = (text) => {
    this.setState({ inputTextValue: text, isOpen: true }, () => this.startTimeout());
  }

  onBlur = () => {
    const { selectedItems } = this.state;
    const { initiallySelectedValues = [], handleSelectedItems } = this.props;
    const finalSelectedValues = selectedItems.map(({ value }) => value);

    if (finalSelectedValues.length !== initiallySelectedValues.length
      || !finalSelectedValues.every(value => initiallySelectedValues.some(initialValue => initialValue === value))) {
      handleSelectedItems(finalSelectedValues);
    }
  }

  /** @param {State['tempSelectedItems']} tempSelectedItems */
  onValueChange = (tempSelectedItems) => {
    this.setState({
      hasSelectedItemsChanged: true,
      tempSelectedItems,
    });
  }

  handleOpenModal = () => {
    const {
      style, apiExtract, modalCallbacks, loading
    } = this.props;
    const {
      selectedItems, buttonLoading
    } = this.state;

    modalCallbacks.onOpen(
      <Controller
        id="searchForPeopleModal"
        confirm
        modal
        title={(__('Search for people...')).replace('...', '')}
        content={(
          // The MultipleAutosuggestForm component follows the format of the original
          // MultipleAutosuggest component, which is why the props passed to it have an
          // specific format that doesn't make much sense with this component data structure
          <MultipleAutosuggestForm
            style={[style]}
            apiExtract={[apiExtract]}
            selected={[selectedItems.map(item => ({ ...item, value: `${item.value} 0` }))]}
            values={selectedItems.map(({ value }) => `${value} 0`)}
            onValueChange={values => this.onValueChange(values.map(value => value.split(' ')[0]))}
          />
        )}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => {
              this.setState({ hasSelectedItemsChanged: false });
              modalCallbacks.onClose();
            }}
          />,
          <Button
            data-action="submit"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => {
              // for some reason, if we use destructuring, the following state values are not updated
              // eslint-disable-next-line react/destructuring-assignment
              if (this.state.hasSelectedItemsChanged) {
                // eslint-disable-next-line react/destructuring-assignment
                this.onSuggestionChange(this.state.tempSelectedItems);
              }
              modalCallbacks.onClose();
            }}
            disabled={loading}
            loading={buttonLoading}
          />
        ]}
      />
    );
  }

  startTimeout = () => {
    const {
      apiExtract, resultExtract, style, fetchData,
    } = this.props;
    const { inputTextValue, selectedItems } = this.state;

    clearTimeout(this.lastRequestId);

    if (inputTextValue !== '') {
      this.setState({ isLoading: true });

      this.lastRequestId = setTimeout(() => {
        const id = this.lastRequestId;
        const selectedLength = this.searchFilter(selectedItems, inputTextValue).length;
        const limit = SUGGESTION_SIZE + selectedLength;

        fetchData({ search: inputTextValue, limit }).then(({ data }) => {
          if (id === this.lastRequestId && inputTextValue !== '') {
            const { edgeName, nodeName = 'node' } = resultExtract;
            /** @type {EntityItem[]} */
            const items = [];

            data[nodeName][edgeName].nodes.forEach(/** @param {any} node */(node) => {
              items.push({
                text: node[apiExtract.name],
                value: `${node.id}`,
                image: style.image ? {
                  avatar: true,
                  src: (node.picture && node.picture.uri)
                      || utils.asset(`/avatars/${utils.normalize(node[apiExtract.name][0].toLowerCase())}.svg`, '')
                } : undefined,
                icon: (!style.image && style.icon) || false,
                'data-id': node.id
              });
            });

            const newSuggestedItems = items.filter(item => !selectedItems.find(({ value }) => value === item.value));

            this.setState({
              isLoading: false,
              suggestedItems: selectedItems.concat(newSuggestedItems),
            });
          }
        });
      }, 400);
    }
  }

  /**
   * @param {EntityItem[]} options
   * @param {string} target
   * @returns {EntityItem[]}
   */
  searchFilter = (options, target) => {
    const newTarget = (utils.normalize(target) || '').toLowerCase();
    const newOptions = options
      .filter(option => (
        utils.normalize(option.text) || ''
      ).toLowerCase().indexOf(newTarget) !== -1);

    return newOptions;
  }

  /**
   * @param {EntityItem} option
   * @param {number} i
   */
  renderSuggestionOption = (option, i) => {
    const { style, apiExtract, selectedItemTextLength = 21 } = this.props;
    const { selectedItems } = this.state;

    if (i === 0) {
      return {
        color: style.color,
        content: utils.renderLongText(`${option.text}`, selectedItemTextLength),
        image: (style.image && option.image) ? {
          avatar: true,
          size: 'medium',
          src: option.image.src
            // @ts-ignore
            || utils.asset(`/avatars/${utils.normalize(option[apiExtract.name].toLowerCase())}.svg`, '')
        } : undefined,
        icon: !style.image && { name: style.icon, size: 'large' },
        onClick: () => {
          if (selectedItems.length > 1) this.handleOpenModal();
        },
        style: { cursor: selectedItems.length > 1 ? 'pointer' : 'unset' }
      };
    }

    if (i === selectedItems.length - 1) {
      return (
        <Label
          className="image"
          size="tiny"
          style={{
            backgroundColor: '#fff',
            fontWeight: 'bold',
            padding: '.5833em .833em .5833em .833em',
            cursor: 'pointer'
          }}
          onClick={this.handleOpenModal}
        >
          {`+${selectedItems.length - 1}`}
        </Label>
      );
    }
    return null;
  }

  render() {
    const {
      className, cssStyle, icon, placeholder, onFocus
    } = this.props;
    const {
      isLoading, isOpen, suggestedItems, inputTextValue, selectedItems,
    } = this.state;
    const options = cloneDeep(suggestedItems);

    return (
      <Dropdown
        ref={(c) => { this.dropdown = c; }}
        upward={false}
        fluid
        multiple
        scrolling
        selection
        style={cssStyle}
        className={className}
        selectOnBlur={false}
        // @ts-ignore
        renderLabel={this.renderSuggestionOption}
        // @ts-ignore
        onChange={(e, { value }) => this.onSuggestionChange(value)}
        onClose={() => this.setState({ isOpen: false, inputTextValue: '' })}
        onFocus={onFocus}
        placeholder={placeholder}
        icon={icon || 'search'}
        onBlur={this.onBlur}
        open={isOpen}
        noResultsMessage={__('No person was found')}
        loading={isLoading && inputTextValue !== ''}
        options={options}
        text={inputTextValue}
        onSearchChange={e => this.onTextChange(/** @type {HTMLInputElement} */(e.target).value)}
        minCharacters={0}
        value={selectedItems.map(({ value }) => value)}
        // @ts-ignore
        search={this.searchFilter}
      />
    );
  }
}
