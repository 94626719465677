import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Header, Image, Container } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import EventCalendar from '../../components/EventCalendar';
import ListView from '../../components/ListView';
import Page from '../../components/Page';

import EventItem from '../Event/Item';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const date = new Date();
const PAGE_SIZE = 400;

@inject('store')
@graphql(gql`query EntityCalendarQuery (
  $id: ID!,
  $limit: Int,
  $offset: Int,
  $period: EntityCommitmentsPeriod
) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        commitments(
          limit: $limit,
          offset: $offset,
          period: $period,
          orderBy: { column: TIME, direction: ASC }
        ) {
          nodes {
            id: dbId
            name
            date
            time
            description
            dateEnd
            timeEnd
            dateLimit
            messageId
            organizationId
            color
            repeats
            expired
            videocallType
            videocallLink
            message {
              entity {
                id: dbId
                fullname
              }
            }
            entity {
              id: dbId
              fullname
            }
            groups (limit: 400) {
              nodes {
                id: dbId
                name
              }
            }
            schedules (limit: 2, orderBy: { column: DATE, direction: DESC }) {
              nodes {
                date
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      offset: 0,
      period: (ownProps.location.query.period && JSON.parse(ownProps.location.query.period)) || { month: date.getMonth() + 1, year: date.getFullYear() }
    }
  })
})
@observer
export default class EntityCalendar extends Page {
  constructor(props) {
    super(props);

    const period = props.location.query.period && JSON.parse(props.location.query.period);
    const day = props.location.query.day ? parseInt(props.location.query.day, 10) : null;

    this.state = {
      year: (period && period.year) || date.getFullYear(),
      month: (period && period.month) ? period.month - 1 : date.getMonth(),
      day: day || date.getDate()
    };
  }

  onChangeDate = (newState) => {
    const { year, month, day } = newState;
    const { router, params } = this.props;
    if (year !== this.state.year || month !== this.state.month || day !== this.state.day) {
      router.push(`/entities/${params.entity_id}/commitments?period=${JSON.stringify({ month: month + 1, year })}&day=${day}`);
    }
    this.setState({ year, month, day: day || date.getDate() });
  }

  renderRow = commitment => <EventItem
    event={commitment}
    to={`/entities/${this.props.params.entity_id}` + (commitment.messageId ? `/messages/${commitment.messageId}` : `/commitments/${commitment.id}`)}
    list
  />

  render() {
    const { data, store, router } = this.props;
    const { year, month, day } = this.state;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const commitments = data.node.commitments;
    const { nodes, pageInfo } = commitments;

    const selectedDayNodes = nodes.filter((commitment) => {
      const d = commitment.date.substring(0, 10).split('-');

      return parseInt(d[2], 10) === this.state.day;
    });
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return (
      <div id="EntityCalendar">
        {
          nodes &&
          <EventCalendar
            year={year}
            month={month}
            day={day}
            onChangeDate={newState => this.onChangeDate(newState)}
            events={nodes}
            selectedDt={new Date(year, month, day)}
            router={router}
          />
        }
        <Header as="h3" attached style={{ border: 'none', background: 'none' }}>
          {utils.simpleDate(new Date(year, month, day, 12, 0), true, 'dddd, LL', lang)}
        </Header>
        {
          selectedDayNodes.length ?
            <ListView
              renderRow={this.renderRow}
              segment={false}
              emptyMessage={''}
              source={selectedDayNodes}
              pagination={{
                graphql: true,
                hasNextPage: pageInfo && pageInfo.hasNextPage,
                loading: data.loading
              }}
              onLoadMore={() => this.loadMore('commitments')}
            />
            :
            <div>
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-32.png')} centered />
              <div style={{ textAlign: 'center' }}> {__('There are no events or commitments')}</div>
            </div>
        }
        {this.props.children}
      </div>
    );
  }
}
