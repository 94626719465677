import React from 'react';
import { graphql } from 'react-apollo';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';
import { Loader, Icon, Image } from 'semantic-ui-react';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Page from '../../components/Page';
import AccessCard from '../../components/AccessCard';
import AccessPortalCard from '../../components/AccessPortalCard';

// eslint-disable-next-line no-unused-vars
const PAGE_SIZE = 200;
@graphql(gql`query ExternalLinksQuery($entityId: ID!, $limit: Int) {
  node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      eid
      externalData
      type
      organization {
        id: dbId
        links(limit: $limit) {
          totalCount
          nodes {
            key
            id: dbId
            icon
            title
            application {
              expiresIn
            }
            url
            type
            replaces
          }
        }
        features {
          api
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: ownProps.location.query.forceFetch ? 'network-only' : 'network-only',
    variables: {
    entityId: ownProps.params.entity_id,
    limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
    orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'CREATED', direction: 'DESC' }
    }
    })
  })

@inject('store') @observer

export default class EntityAccesses extends Page {
  componentDidMount() {
    const { store } = this.props;
    // const access = store.entity.type == 'STUDENT' && store.entity.organization.features.api && store.entity.organization.links && store.entity.organization.links.totalCount > 0 && !store.entity.eid == null;
    const access = store.entity.type == 'STUDENT' && store.entity.organization.features.api && store.entity.organization.links && store.entity.organization.links.totalCount > 0;
    if (!access) {
      this.props.router.push(`/entities/${store.entity.id}/messages`);
    }
    this.setState({ loading: false });
  }

  render() {
    const { data, location } = this.props;

    const getEntityIdFromURL = utils.getEntityIdFromURL(location);

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const organization = data && data.node && data.node.organization;
    const externalLinks = (organization && organization.links && organization.links.nodes) || [];

    const externalLinksIntegrationFiltered = externalLinks.filter(__link => __link.type !== 'INTEGRATION' || (__link.type === 'INTEGRATION' && this.props.data.node.eid));

    if (!externalLinksIntegrationFiltered.length) {
      return (
        <div id="ExternalAccessContainer">
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <h2 style={{ paddingLeft: '0.714rem', fontSize: '2.29rem', lineHeight: '2.714rem', paddingBottom: '0.214rem' }}>{__('External Accesses')}</h2>
            <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803817990" target="_blank" rel="noopener noreferrer">
              <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '1.429rem', marginLeft: '0.571rem' }} name="circle question" />
            </a>
          </div>
          <div id="ExternalAccess" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Image src={utils.asset('/images/astronaut.png')} style={{ maxHeight: 260, marginTop: 30 }} centered />
            <p style={{ color: '#1D1D1D', fontSize: 20, margin: '20px 0px 8px' }}>{__('No external access found')}</p>
            <p style={{ color: '#00000099' }}>{__('Links have not yet been added to display on this screen')}</p>
          </div>
        </div>
      );
    }

    return (
      <div id="EntityExternalAccessHeader">
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <h2 style={{ paddingLeft: '0.714rem', fontSize: '2.29rem', lineHeight: '2.714rem', paddingBottom: '0.214rem' }}>{__('External Accesses')}</h2>
          <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803817990" target="_blank" rel="noopener noreferrer">
            <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '1.429rem', marginLeft: '0.571rem' }} name="circle question" />
          </a>
        </div>
        <div id="ExternalAccessEntityContent" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '1.786rem' }}>
          {externalLinksIntegrationFiltered.map(__link => (
            __link.type === 'PORTAL'
              ? <AccessPortalCard
                getEntityIdFromURL={getEntityIdFromURL}
                link={__link}
              />
              : <AccessCard
                getEntityIdFromURL={getEntityIdFromURL}
                link={__link}
                eid={this.props.data.node.eid}
                externalData={this.props.data.node.externalData}
              />)
          )}
        </div>
      </div>
    );
  }
}
