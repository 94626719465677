import React from 'react';
import { Link } from 'react-router';
import { Menu, Label, Icon, Header, Container } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Avatar from '../../components/Avatar';

import { __ } from '../../i18n';

export default class EntityHeader extends React.Component {
  static fragments = {
    entity: gql`
      fragment EntityHeader on Entity {
        id: dbId
        fullname
        picture {
          uri
          id: dbId
          key
        }
      }`
  }

  render() {
    const { entity } = this.props;

    if (!entity) return null;

    const src = entity.picture && entity.picture.uri;

    return (
      <Menu
        id="EntityHeader"
        vertical
        fluid
        secondary
        style={{ justifyContent: 'center', marginTop: 0, textAlign: 'center' }}
      >
        <Menu.Item key={0} fitted>
          <Container
            className="hoverReveal"
            as={Link}
            to={`/entities/${entity.id}/edit/picture`}
            style={{
              width: '140px',
              display: 'inline-block'
            }}
          >
            <div
              style={{
                height: '140px',
                width: '140px'
              }}
            >
              <Avatar size="small" src={src} alt={entity.fullname} circular style={{ width: '140px', height: '140px', objectFit: 'cover' }} />
            </div>
            <div
              style={{
                height: '140px',
                width: '140px',
                backgroundColor: 'initial',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                opacity: !src ? 1 : undefined
              }}
            >
              <Label
                style={{ margin: 10, opacity: '0.7' }}
                color="black"
                size="mini"
              >
                <Icon name="image" />
                {__('Change image')}
              </Label>
            </div>
          </Container>
        </Menu.Item>

        <Menu.Item key={1}>
          <Header as="h4" style={{ margin: '8px auto' }}>
            {(entity.fullname.length > 15) ? (entity.fullname.substring(0, 15) + '...') : entity.fullname}
          </Header>
        </Menu.Item>

        {
          this.props.children !== undefined && <Menu.Item key={4}>{this.props.children}</Menu.Item>
        }
      </Menu>
    );
  }
}
